import { Fragment, useEffect, useState, memo, useCallback } from "react"
import { useAppContext } from "@/components/AppContext"
import { Button, Col, DatePicker, Drawer, Form, Row, Spin, Typography, message, Modal, Select, Tag, Checkbox } from "antd"
import { useForm } from 'antd/lib/form/Form'
import { YODA_CDN_URL, PUJA_PRESIGN_URL } from '@/utils/constants'
import { LoadingOutlined, RightOutlined } from "@ant-design/icons"
import PujaMedia from "../PujaMedia"
import MessageCard from "../MessageCard"
import { PujaUpdateForm as PujaUpdateFormType, parseStoreResponse, preparePayload } from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import { ScheduledStates, StoreData } from "../../types"
import { getMonetisationPujaApi } from "@/utils/helper"
import EmptyState from "@/components/ui/EmptyState"
import useFetch from "@/hooks/useFetch"
import moment from "moment-timezone"

const { Title } = Typography
const { Option } = Select

interface PujaUpdateFormProps {
    showForm: boolean
    store: { id: string, title: string }
    handleCloseForm: (updateStoreList?: boolean) => void
}

const PujaUpdateForm: React.FC<PujaUpdateFormProps> = ({ showForm, store, handleCloseForm }) => {
    const [pujaUpdateForm] = useForm()
    const navigate = useNavigate()
    const { networkInstance, countryState, app } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)

    const [currentPujaState, setCurrentPujaState] = useState<string>('')
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const [showReschedule, setShowReschedule] = useState<boolean>(false)
    const [updateConfirmDialog, setUpdateConfirmDialog] = useState<boolean>(false)

    const fetchPujaUpdates = useCallback(async (storeId: string | undefined) => {
        if (!storeId) {
            return { err: 'Store ID is required' }
        }

        try {
            const storeResponse = await monetisationPujaApi.storesApi.getPujaUpdatesByStoreId(storeId, countryState?.countryCode)
            const storeResponseData: StoreData = storeResponse.data.data
            return {
                data: storeResponseData
            }
        } catch (err) {
            return {
                err: "Failed to fetch puja updates details"
            }
        }
    }, [countryState?.countryCode, monetisationPujaApi.storesApi])

    const { data, error, loading } = useFetch(fetchPujaUpdates, store?.id)

    useEffect(() => {
        if (!data) {
            return
        }
        const parsedStoreResponse = parseStoreResponse(data)
        pujaUpdateForm?.setFieldsValue(parsedStoreResponse)
        setCurrentPujaState(
            (data?.puja_store_state && data?.puja_store_state?.length > 0)
                ? data?.puja_store_state
                : 'BOOKED'
        )
    }, [data, pujaUpdateForm])

    const updatePujaDetails = async (values: PujaUpdateFormType) => {
        if (values?.puja_end_time && !values?.priest_medias) {
            message.warning('Please upload Puja end video', 3)
            return
        }
        const payload = preparePayload(values)
        try {
            await monetisationPujaApi.storesApi.updatePujaStatus(
                store?.id,
                payload,
                countryState?.countryCode
            )
            handleCloseForm(true)
            message.success({ content: 'Puja Status updated Successfully', duration: 3 })
        } catch (error: any) {
            message.error({ content: error.response.data.error.message, duration: 3 })
            console.log({ error })
        }
    }

    const submitForm = () => {
        pujaUpdateForm
            .validateFields()
            .then((values: any) => {
                updatePujaDetails(values)
            })
            .catch((error) => {
                console.log('Puja Update form error', error)
            })
    }

    return (
        <Drawer
            title={
                <Row justify="space-between" style={{ width: '100%', alignItems: 'center' }}>
                    <span>Puja Update Form</span>
                    {pujaUpdateForm?.getFieldValue('scheduled_states')?.length > 0 ?
                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'end', gap: '12px', flexBasis: '60%' }}>
                            {pujaUpdateForm.getFieldValue('scheduled_states')?.map((scheduledState: ScheduledStates, index: number, array: ScheduledStates[]) => {
                                const formattedDate = scheduledState.scheduled_at
                                    ? moment(scheduledState.scheduled_at).format('DD-MMM-YY HH:mm')
                                    : 'Current state';

                                return (
                                    <>
                                        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Tag color="blue">
                                                {scheduledState.state}
                                            </Tag>
                                            <span style={{
                                                fontSize: '12px',
                                                color: 'gray',
                                                marginTop: '4px'
                                            }}>
                                                {formattedDate}
                                            </span>
                                        </div>
                                        <div>
                                            {index < array.length - 1 && (
                                                <RightOutlined />
                                            )}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        :
                        <Tag color="blue">{currentPujaState}</Tag>
                    }
                </Row>
            }
            placement='right'
            onClose={() => {
                handleCloseForm()
                pujaUpdateForm?.resetFields()
            }}
            visible={showForm}
            destroyOnClose={true}
            width={800}
        >
            {error ? <EmptyState /> :
                loading ?
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
                    </div>
                    :
                    <Fragment>
                        <Form
                            form={pujaUpdateForm}
                            layout='vertical'
                            initialValues={{ remember: true }}
                            onFinish={submitForm}
                            onFieldsChange={() => setIsEnabled(true)}
                            onValuesChange={(values) => {
                                if (values?.new_puja_state === "RESCHEDULED") {
                                    navigate(`/monetisation/puja/${store?.id}/reschedule`)
                                }
                            }

                            }
                            style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
                        >
                            <Col style={{ width: '100%' }}>
                                <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <Title level={5} style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {store?.title}
                                    </Title>
                                </div>

                                <div style={{ marginTop: '10px' }}>
                                    <Form.Item
                                        name='puja_start_time'
                                        label='Puja start Date & Time'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Pick time',
                                            },
                                        ]}
                                    >
                                        <DatePicker showTime />
                                    </Form.Item>

                                    <Form.Item
                                        label='Puja start media'
                                    >
                                        <Row>
                                            <Form.List name={['puja_media']}>
                                                {(fields, { add, remove, move }) => {
                                                    return (
                                                        <PujaMedia
                                                            onSortEnd={({ oldIndex, newIndex }) => {
                                                                move(oldIndex, newIndex)
                                                            }}
                                                            distance={1}
                                                            helperClass='sortableHelper'
                                                            fields={fields}
                                                            add={add}
                                                            remove={remove}
                                                            cdnUrl={YODA_CDN_URL}
                                                            preSignUrl={PUJA_PRESIGN_URL}
                                                            datatype='Puja start media'
                                                            form={pujaUpdateForm}
                                                        />
                                                    )
                                                }}
                                            </Form.List>
                                        </Row>
                                    </Form.Item>

                                    <Form.Item
                                        name='puja_end_time'
                                        label='Puja end Date & Time'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Pick time',
                                            },
                                        ]}
                                    >
                                        <DatePicker showTime />
                                    </Form.Item>

                                    <Form.Item noStyle shouldUpdate={(prevValue, currenValue) => prevValue?.puja_end_time !== currenValue?.puja_end_time}>
                                        {({ getFieldValue }) => {
                                            if (getFieldValue('puja_end_time')) {
                                                return <Form.Item label='Add Puja End Media'>
                                                    <Row>
                                                        <Form.List name={['priest_medias']}>
                                                            {(fields, { add, remove, move }) => {
                                                                return (
                                                                    <PujaMedia
                                                                        onSortEnd={({ oldIndex, newIndex }) => {
                                                                            console.log('oldIndex', oldIndex, 'newIndex', newIndex);
                                                                            move(oldIndex, newIndex);
                                                                        }}
                                                                        distance={1}
                                                                        helperClass='sortableHelper'
                                                                        fields={fields}
                                                                        add={add}
                                                                        remove={remove}
                                                                        cdnUrl={YODA_CDN_URL}
                                                                        preSignUrl={PUJA_PRESIGN_URL}
                                                                        datatype='Puja end Medias'
                                                                        form={pujaUpdateForm}
                                                                    />
                                                                );
                                                            }}
                                                        </Form.List>
                                                    </Row>
                                                </Form.Item>
                                            }
                                        }}

                                    </Form.Item>

                                    <Row justify="space-between" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                        <Form.Item
                                            label='Message cards'
                                            style={{ width: '100%' }}
                                        >
                                            <Form.List name={['message_cards']}>
                                                {(fields, { add, remove, move }) => {
                                                    return (
                                                        <MessageCard
                                                            onSortEnd={({ oldIndex, newIndex }) => {
                                                                console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                                                move(oldIndex, newIndex)
                                                            }}
                                                            distance={1}
                                                            helperClass='sortableHelper'
                                                            form={pujaUpdateForm}
                                                            fields={fields}
                                                            add={add}
                                                            remove={remove}
                                                            cdnUrl={YODA_CDN_URL}
                                                            preSignUrl={PUJA_PRESIGN_URL}
                                                            datatype='Message card'
                                                        />
                                                    )
                                                }}
                                            </Form.List>
                                        </Form.Item>
                                    </Row>
                                </div>
                                <Row justify="space-between" style={{ width: '100%' }}>
                                    <Button type="primary" style={{ flexBasis: '48%' }} disabled={!isEnabled} onClick={() => setUpdateConfirmDialog(true)}>Update</Button>
                                    <Button danger style={{ flexBasis: '48%' }} onClick={() => setShowReschedule(true)}>Reschedule / Cancel</Button>
                                </Row>
                            </Col>
                            <Modal
                                title="Puja Reschedule / Cancel"
                                visible={showReschedule}
                                onOk={submitForm}
                                onCancel={() => setShowReschedule(false)}
                                width="35vw"
                                okText='Update'
                            >
                                <Form.Item
                                    label="Puja state"
                                    name={['new_puja_state']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Puja state',
                                        },
                                    ]}
                                >
                                    <Select placeholder='Select Puja state'>
                                        <Option value='RESCHEDULED'>RESCHEDULED</Option>
                                        <Option value='CANCELLED'>CANCELLED</Option>
                                    </Select>
                                </Form.Item>
                            </Modal>
                            <Modal
                                title="Puja update"
                                visible={updateConfirmDialog}
                                onOk={submitForm}
                                onCancel={() => setUpdateConfirmDialog(false)}
                                width="35vw"
                                okText='Update'
                            >
                                <Form.Item
                                    label="Puja state"
                                    name={['new_puja_state']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select Puja state',
                                        },
                                    ]}
                                >
                                    <Select defaultValue={'BOOKED'} placeholder='Select Puja state'>
                                        <Option value='BOOKED'>BOOKED</Option>
                                        <Option value='SCHEDULED'>SCHEDULED</Option>
                                        <Option value='STARTED'>STARTED</Option>
                                        <Option value='COMPLETED'>COMPLETED</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item noStyle
                                    shouldUpdate={true}
                                >
                                    {({ getFieldValue }) =>
                                        <Form.Item name={"change_status_immediately"} valuePropName="checked">
                                            <Checkbox disabled={getFieldValue('is_scheduled') === true}>Change the status immediately</Checkbox>
                                        </Form.Item>}
                                </Form.Item>
                                <Form.Item noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        currentValues?.change_status_immediately !==
                                        prevValues?.change_status_immediately
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        <Form.Item
                                            name={'init_scheduled_time'}
                                            label='Scheduled update'
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: getFieldValue('change_status_immediately') === true ? false : true,
                                                    message: 'Pick time',
                                                },
                                            ]}
                                        >
                                            <DatePicker showTime disabled={getFieldValue('change_status_immediately') === true} />
                                        </Form.Item>
                                    }
                                </Form.Item>
                            </Modal>
                        </Form>
                    </Fragment>
            }
        </Drawer >
    )
}

export default memo(PujaUpdateForm)