import { LinkOutlined } from "@ant-design/icons"
import { Button, Col, Divider, Row, Tag, Typography } from "antd"
import {
    ManageSectionItem,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'

interface ShowSectionInfoProps {
    data?: Partial<ManageSectionItem>
}

const SectionInfoShort: React.FC<ShowSectionInfoProps> = ({ data }) => {
    return (
        <div
            style={{ width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', marginBottom: '10px', padding: '10px' }}
        >
            {/* First Row: Title and Description (50% each) */}
            <Row align="middle" style={{ marginBottom: '12px' }}>
                {/* Title Column - 50% */}
                <Col span={12} style={{ borderRight: '1px solid #f0f0f0', paddingRight: '12px' }}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: '14px',
                            display: 'block',
                            width: '100%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                        title={data?.title || 'N/A'}
                    >
                        {data?.title || 'N/A'}
                    </Typography.Text>
                </Col>

                {/* Description Column - 50% */}
                <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Typography.Text
                        style={{
                            fontSize: '14px',
                            display: 'block',
                            width: '100%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                        title={data?.description || 'N/A'}
                    >
                        {data?.description || 'N/A'}
                    </Typography.Text>
                </Col>
            </Row>

            {/* Horizontal Divider */}
            <Divider style={{ margin: '0 0 12px 0' }} />

            {/* Second Row: Section Type, Item Type, and Button (33% each) */}
            <Row align="middle" justify="space-between">
                {/* Section Type - 33% */}
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tag color="#108ee9">{data?.section_type || 'N/A'}</Tag>
                </Col>

                {/* Item Type - 33% */}
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tag color="default">{data?.item_type || 'N/A'}</Tag>
                </Col>

                {/* Section Link - 33% */}
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        type="link"
                        icon={<LinkOutlined />}
                        onClick={() =>
                            window.open(
                                `/monetisation/puja/manage_page_section?current_tab=sections&section_id=${data?.id}`,
                                '_blank'
                            )
                        }
                    >
                        Section
                    </Button>
                </Col>
            </Row>
        </div>
    )
}


export default SectionInfoShort