import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Response } from './types'
import { Address, AddressData, AddressPayload } from './types/address'

class AddressesApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getAllAddress = () =>
    this.api.get<Response<AddressData>>(`/gw2/puja/internal/v1/address`)

  getAddressById = (id: string) =>
    this.api.get<Response<Address>>(`/gw2/puja/internal/v1/address/${id}`)

  createAddress = (payload: AddressPayload) =>
    this.api.post(`/gw2/puja/internal/v1/address`, payload)

  updateAddress = (id: string, payload: AddressPayload) =>
    this.api.put(`/gw2/puja/internal/v1/address/${id}`, payload)
}

export default AddressesApi
