import React, { useCallback, useEffect, useState } from 'react'
import { Form, PaginationProps, message, Spin } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import { useAppContext } from '@/components/AppContext'
import { useParams, useSearchParams } from 'react-router-dom'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import _ from 'lodash'
import BatchView from './components/BatchView'
import BatchListPage from './components/BatchListPage'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import {
  Batch,
  Member,
  MetaData,
  PoojaMemberData,
  SKUFilters,
  TransliterationData,
  Users,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import CompletedPreviewModal from './components/CompletedPreviewModal'
import PreviewModal from './components/PreviewModal'
import UploadMediaModal from './components/UploadMediaModal'
import Error from '@/components/Error'
import CreateDeliveryOrder from './components/CreateDeliveryOrder'
import { deliveryOrderPayload } from './utils/helper'
import StoreMediaModal from './components/UploadStoreMediaModal'

interface DataType {
  batch_id: string
  product_name: string
  store_name: string
}

interface DataType {
  key: React.Key
  name: string
  age: number
  address: string
}

const getPageBreadcrumbs = (TempleName: string, hubId: string) => [
  {
    path: '/monetisation/offerings',
    breadcrumbName: 'Hub List',
  },
  {
    path: `/monetisation/offerings/hub/${hubId}`,
    breadcrumbName: TempleName,
  },
  {
    path: '/monetisation/offerings/batches',
    breadcrumbName: 'Batch List',
  },
]

const BatchListScreen: React.FC = () => {
  const { networkInstance, monetisationRoles, languageState, countryState } = useAppContext()
  const { monetisationOfferingsApi, userApi } = networkInstance.clientWithHeaders

  let { hubId, templeId } = useParams()
  let [batchData, setBatchData] = useState<Batch[]>([])
  let [paginatedBatchData, setPaginatedBatchData] = useState<Batch[]>([])
  let [skuFilters, setSkuFilters] = useState<SKUFilters[]>([])
  const [showbatchView, setShowbatchView] = React.useState(false)
  const [isPreviewDone, setIsPreviewDone] = React.useState(false)
  const [showPreview, setShowPreview] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState<string>()
  const [totalRecord, setTotalRecord] = React.useState<number>(0)
  const [isComplete, setIsComplete] = React.useState(false)
  const [isCompletedPoojaService, setCompletedPoojaService] = React.useState(false)
  const [currentCompletedVideo, setCurrentCompletedVideo] = React.useState('')

  const [isPaginationChanged, setPaginationChanged] = React.useState(false)
  const [metaMap, setMetaMap] = React.useState<MetaData | undefined>()

  const [reducedItemNoCount, setReducedItemCount] = React.useState()
  const [currentBatchView, setCurrentBatchView] = React.useState<
    Batch | undefined
  >()
  const [isGenerateBatch, setIsGenerateBatch] = React.useState<boolean>(false)
  const [showMediaUploadModal, setShowMediaUploadModal] = React.useState<boolean>(false)
  const [showUploadButton, setShowUploadButton] = useState<boolean>(false)
  const [selectionType] = useState<string>('checkbox')
  const [loading, setLoading] = useState<boolean>(false)
  const [errorPage, setErrorPage] = React.useState<boolean>(false)
  const [showCreateOrderForm, setShowCreateOrderForm] = React.useState<boolean>(false)
  const [isDeliveryOrderCreated, setIsDeliveryOrderCreated] = React.useState<boolean>(false)
  const [selectedRow, setSelectedRow] = React.useState<any>()
  const [hubAssociates, setHubAssociates] = React.useState<Users[]>([])
  const [isStoreMediaModalVisible, setIsStoreMediaModalVisible] = React.useState<boolean>(false)
  const [storeDetails, setStoreDetailsState] = React.useState({
    intro_video_url: null,
    outro_video_url: null,
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50, 100, 200],
    showSizeChanger: true,
  })

  const [batchForm] = Form.useForm()
  const [orderMediaForm] = Form.useForm()
  const [mediaUploadForm] = Form.useForm()
  const [createOrderForm] = Form.useForm()
  const [currentStep, setCurrentStep] = useState(1)
  const [searchParams] = useSearchParams()

  const getBatchList = useCallback(
    async (isForcefully?: boolean) => {
      if (!templeId || !monetisationRoles?.email || (isPaginationChanged && !isForcefully)) {
        return
      }
      try {
        const batchListList = await monetisationOfferingsApi.batchApi.getBatchList({
          store_id: templeId || '',
          user_email: monetisationRoles?.email || '',
          batch_status: isComplete ? 'completed' : '',
          limit: pagination.pageSize!,
          offset:
            Number((pagination?.current || 1) - 1) * Number(pagination.pageSize),
          ...((currentTab !== 'all_sku') && { inventory_id: currentTab }),
          sku_filter: !skuFilters?.length ? true : false
        })
        setPagination({ ...pagination, total: batchListList?.data?.data?.total || 10 })
        setMetaMap(batchListList?.data?.data?.meta_data)

        setPaginatedBatchData(batchListList?.data?.data?.batches)
        if (!batchListList?.data?.data?.batches) {
          message.warning('Batch list is empty')
          return
        }
        if (isForcefully) {
          message.success('Batch list refreshed successfully')
        }
        if (!skuFilters?.length)
          setSkuFilters(batchListList?.data?.data?.sku_filters)
        setBatchData(batchListList?.data?.data?.batches)
        setTotalRecord(batchListList?.data?.data?.batches.length)
        const reducedValue = batchListList?.data?.data?.batches.reduce(
          (acc: any, cur: Batch) => {
            if (cur.status)
              acc[cur.inventory_id] = acc[cur.inventory_id]
                ? acc[cur.inventory_id] + 1
                : 1
            return acc
          },
          {},
        )
        setReducedItemCount(reducedValue)
      } catch (error) {
        setErrorPage(true)
        console.log(error)
      }
    },
    [templeId, monetisationRoles?.email, isPaginationChanged, monetisationOfferingsApi.batchApi, isComplete, pagination?.current, pagination?.pageSize, currentTab],
  )

  const getHubAssociates = useCallback(async () => {
    try {
      const userList = await userApi.getUserList('dropdown', 'hub_associate', hubId)
      setHubAssociates(userList?.data?.data?.users)
    } catch (error) {
      message.error({
        content: 'Error while fetching user List - hub Associates',
        duration: 3,
      })
      console.log(error)
    }
  }, [hubId]);


  useEffect(() => {
    getBatchList();
    getHubAssociates();
  }, [getBatchList, isComplete])



  useEffect(() => {
    if (currentBatchView) {
      const config = localStorage.getItem(currentBatchView?.batch_id)
      const parsedConfig = JSON.parse(config || '{}')
      if (currentBatchView.product_type === 'pooja') {
        if (parsedConfig?.namelistDownload) {
          setCurrentStep(3)
        } else if (parsedConfig?.orderMediaUpload) {
          setCurrentStep(2)
        } else {
          setCurrentStep(1)
        }
      }
      if (currentBatchView.product_type === 'item') {
        if (parsedConfig?.namelistDownload) {
          setCurrentStep(2)
        } else {
          setCurrentStep(1)
        }
      }
      if (currentBatchView.product_type === 'prayer') {
        if (parsedConfig?.namelistDownload) {
          setCurrentStep(2)
        } else if (parsedConfig?.prayerDownload) {
          setCurrentStep(1)
        }
      }
    }
  }, [currentBatchView])

  const updateAssociateForBatch = async (batchId: string, associateId: string) => {
    try {
      const payload = {
        associate_id: associateId,
        editor_email: monetisationRoles?.email
      }

      await monetisationOfferingsApi.batchApi.updateAssociate(batchId, 'ASSIGN_ASSOCIATE', payload)
      message.success({
        content: 'assigned hub associate successfully',
        duration: 3,
      })
      getBatchList()
      getHubAssociates()
    } catch (error) {
      message.error({
        content: 'Error while generating batch',
        duration: 3,
      })
    }
  }

  const generateBatch = async () => {
    setIsGenerateBatch(true)
    try {
      await monetisationOfferingsApi.batchApi.generateBatches({
        store_id: templeId || '',
        user_email: monetisationRoles?.email || '',
      })
      message.success({
        content: 'Batches generated successfully',
        duration: 3,
      })
      getBatchList()
      setIsGenerateBatch(false)
    } catch (error) {
      message.error({
        content: 'Error while generating batch',
        duration: 3,
      })
    }
  }

  const onSearch = async (crieteria: string) => {
    if (crieteria) {
      const key = "search"
      try {
        message.open({
          key,
          type: 'loading',
          content: 'Searching...',
        });

        const batchResponse = await monetisationOfferingsApi?.batchApi?.searchBatches(crieteria?.trim())
        message.destroy(key);
        if (batchResponse?.data?.error) {
          message.error(batchResponse?.data?.error?.message);
        } else {
          setPaginatedBatchData([batchResponse?.data?.data])
        }
      } catch (error) {
        message.error(`Error occured:${error}`);
      }
    } else {
      getBatchList();
    }
    //removed as part of  new API
    /*  if (currentTab === 'all_sku') {
       searchedBatches = batchData.filter(
         (batch: Batch) =>
           batch?.batch_code?.includes(crieteria) ||
           batch?.batch_id?.includes(crieteria),
       )
     } else {
       searchedBatches = paginatedBatchData.filter(
         (batch: Batch) =>
           batch?.batch_code?.includes(crieteria) ||
           batch?.batch_id?.includes(crieteria),
       )
     } */

  }
  const getNameTranslation = async (
    batch_id: string,
    nameList: string[],
    callback: any,
  ) => {
    // if (nameList?.length > 100) {
    //   const translationResponse = await monetisationOfferingsApi.batchApi.translateToEnglishPost(
    //     batch_id || '', { namelist: nameList }
    //   )
    //   callback(translationResponse?.data)
    // } else {
    //   const translationResponse = await monetisationOfferingsApi.batchApi.translateToEnglish(
    //     batch_id || '',
    //     nameList || [''],
    //   )
    //   callback(translationResponse?.data)
    // }
    const hindiTranslation = await monetisationOfferingsApi.batchApi.translateStringsToHindi(nameList)
    callback(hindiTranslation?.data.data?.output?.strings.map((item: string, index: number) => `${item} - [ ${nameList[index]} ]`))
  }
  const onViewBatchClick = async (batch: Batch) => {
    message.loading({ content: 'Fetching translation', key: 'trans' })

    if (batch?.product_type === 'pooja') {
      let nameList: string[][] = batch.pooja_member_data?.map((item: PoojaMemberData) => {
        const names = item?.members?.map((member: Member) => member?.full_name)
        return names?.length ? names : [""]
      }
        ,
      )
      try {
        const translationResponse = await monetisationOfferingsApi.batchApi.translatePujaTextToEnglish(
          batch.batch_id,
          nameList,
        )

        let translatedBatch = {
          ...batch,
          pooja_member_data: batch?.pooja_member_data?.map((pujaMember: PoojaMemberData, poojaMembersIndex: number) => {
            return {
              ...pujaMember, members: pujaMember?.members?.map((members: Member, membersIndex: number) => {
                return { ...members, full_name: translationResponse?.data?.[poojaMembersIndex]?.[membersIndex] }
              })
            }
          }),

        }

        setCurrentBatchView(translatedBatch)
        setShowbatchView(true)
      } catch (error) {
        message.error({ content: "Translation failed" })
      }

    } else {
      const storeById = await monetisationOfferingsApi.storesApi.getStoreById(templeId || '', countryState.countryCode, languageState.monetisationLanguage)
      // getNameTranslation(
      //   batch.batch_id,
      //   batch.offering_names,
      //   (result: string[]) => {
      //     // message.destroy('trans')
      //     setCurrentBatchView({
      //       ...batch,
      //       offering_names: result || [''],
      //       intro_url: inventoryById?.data?.data?.IntroVideoUrl,
      //       outro_url: inventoryById?.data?.data?.OutroVideoUrl
      //     })
      //     setShowbatchView(true)
      //   },
      // )

      if (!batch.transliterated_names) {
        try {
          const response = await monetisationOfferingsApi.batchApi.translateOfferingNamesToHindi(batch.batch_id);

          message.destroy('trans')
          const transliterationData = response?.data?.data?.transliteration_data;

          const newTransliteratedNamesMap: TransliterationData = {
            hi: transliterationData.hi,
          };

          const updatedBatch = {
            ...batch,
            transliterated_names: newTransliteratedNamesMap,
            intro_url: storeById?.data?.data?.intro_video_url,
            outro_url: storeById?.data?.data?.outro_video_url,
          };

          setCurrentBatchView(updatedBatch);
          message.success({ content: 'Names translated successfully', key: 'trans', duration: 2 });
        } catch (error) {
          console.error('Failed to transliterate names:', error);
          message.error({ content: 'Translation failed', key: 'trans', duration: 2 });
        }
      } else {
        message.destroy('trans')

        setCurrentBatchView({
          ...batch,
          intro_url: storeById?.data?.data?.intro_video_url,
          outro_url: storeById?.data?.data?.outro_video_url,
        });
      }
      setShowbatchView(true)
    }
  }

  const onTransliterateNamesClick = async (batch?: Batch) => {
    if (!batch) {
      console.error('Batch is undefined');
      return;
    }

    message.loading({ content: 'Translating names...', key: 'trans' });

    try {
      const response = await monetisationOfferingsApi.batchApi.translateOfferingNamesToHindi(batch.batch_id);
      const transliterationData = response?.data?.data?.transliteration_data;

      const newTransliteratedNamesMap: TransliterationData = {
        hi: transliterationData.hi,
      };

      const updatedBatch = {
        ...batch,
        transliterated_names: newTransliteratedNamesMap,
      };

      setCurrentBatchView(updatedBatch);
      message.success({ content: 'Names translated successfully', key: 'trans', duration: 2 });
    } catch (error) {
      console.error('Failed to transliterate names:', error);
      message.error({ content: 'Translation failed', key: 'trans', duration: 2 });
    }
  };

  const closeHandler = () => {
    setShowbatchView(false)
  }
  const handleNameListExport = () => {
    if (currentBatchView) {
      const config = localStorage.getItem(currentBatchView.batch_id)
      const parsedConfig = JSON.parse(config || '{}')

      localStorage.setItem(
        currentBatchView.batch_id,
        JSON.stringify({ ...parsedConfig, namelistDownload: true }),
      )
      if (currentBatchView.product_type === 'pooja') {
        setCurrentStep(3)
      }
      if (currentBatchView.product_type === 'item') {
        setCurrentStep(2)
      }
      if (currentBatchView.product_type === 'prayer') {
        setCurrentStep(2)
      }
    }
  }
  const handlePrayerExport = () => {
    if (currentBatchView) {
      const config = localStorage.getItem(currentBatchView.batch_id)
      const parsedConfig = JSON.parse(config || '{}')

      localStorage.setItem(
        currentBatchView.batch_id,
        JSON.stringify({ ...parsedConfig, prayerDownload: true }),
      )
      setCurrentStep(2)
    }
  }
  const handleFormSubmit = () => {
    if (!currentBatchView) {
      return
    }
    batchForm
      .validateFields()
      .then(async values => {
        const batchFormData = batchForm.getFieldsValue()
        const formData = {
          hash_code: currentBatchView?.hash_code,
          video_url: batchFormData?.videoType === "youtube" ? batchFormData?.video_url : batchFormData?.video_url?.[0],
          media_type: batchFormData?.videoType,
          client_video_processor: true,
          force_upload: batchFormData?.force_upload
        }
        try {
          await monetisationOfferingsApi.batchApi.completeBatch(
            currentBatchView.batch_id,
            formData,
            countryState?.countryCode
          )
          message.success({
            content: 'Batch updated successfully',
            duration: 3,
          })
          setShowbatchView(false)
          batchForm.resetFields()
          getBatchList()
        } catch (error) {
          message.error({
            content: 'Error while updating batch',
            duration: 3,
          })
          console.log(error)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const handleOrderMediaFormSubmit = (formSubmitFlag: boolean) => {
    if (!currentBatchView) {
      return
    }
    if (!formSubmitFlag) {
      const config = localStorage.getItem(currentBatchView.batch_id)
      const parsedConfig = JSON.parse(config || '{}')

      localStorage.setItem(
        currentBatchView.batch_id,
        JSON.stringify({ ...parsedConfig, orderMediaUpload: true }),
      )
      if (currentBatchView.product_type === 'pooja') {
        setCurrentStep(2)
      }
      return
    }

    orderMediaForm
      .validateFields()
      .then(async values => {
        const orderMediaFormData = orderMediaForm.getFieldsValue()
        let payload = orderMediaFormData?.order_media?.map((item: any) => ({
          type: orderMediaFormData?.order_media_type,
          media_url: item,
        }))
        if (!orderMediaFormData || !orderMediaFormData?.order_media_type) {
          message.warning("Please select order media")
          return
        }
        const formData = {
          order_media: payload,
          is_sankalp_media: orderMediaFormData?.is_sankalp_media
        }
        try {
          await monetisationOfferingsApi.batchApi.uploadOrderMedia(
            currentBatchView.batch_id,
            formData,
          )
          message.success({
            content: 'Order Media updated successfully',
            duration: 3,
          })

          const config = localStorage.getItem(currentBatchView.batch_id)
          const parsedConfig = JSON.parse(config || '{}')

          localStorage.setItem(
            currentBatchView.batch_id,
            JSON.stringify({ ...parsedConfig, orderMediaUpload: true }),
          )
          if (currentBatchView.product_type === 'pooja') {
            setCurrentStep(2)
          }
        } catch (error) {
          message.error({
            content: 'Error while updating batch',
            duration: 3,
          })
          console.log(error)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const initiatePreviewStatus = useCallback(() => {
    if (!currentBatchView?.batch_id) {
      return
    }
    const config = localStorage.getItem(currentBatchView.batch_id)
    const parsedConfig = JSON.parse(config || '{}')
    if (parsedConfig?.isPreviewDone) {
      setIsPreviewDone(true)
    }

    localStorage.setItem(
      currentBatchView.batch_id,
      JSON.stringify({ ...parsedConfig }),
    )
  }, [currentBatchView?.batch_id])

  useEffect(() => {
    initiatePreviewStatus()
  }, [initiatePreviewStatus])

  const updatePreviewStatus = (previewStatus: boolean) => {
    if (!currentBatchView?.batch_id) {
      return
    }
    const config = localStorage.getItem(currentBatchView.batch_id)
    const parsedConfig = JSON.parse(config || '{}')

    localStorage.setItem(
      currentBatchView.batch_id,
      JSON.stringify({ ...parsedConfig, isPreviewDone: previewStatus }),
    )
  }
  const resetPreview = () => {
    if (!currentBatchView?.batch_id) {
      return
    }

    const config = localStorage.getItem(currentBatchView.batch_id)
    const parsedConfig = JSON.parse(config || '{}')
    localStorage.setItem(
      currentBatchView.batch_id,
      JSON.stringify({ ...parsedConfig, isPreviewDone: false }),
    )
  }

  const openPreviewModal = () => {
    handleOpenPreviewModal()
  }
  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current: number,
    pageSize: number,
  ) => {
    setPaginationChanged(true);
    setPagination({ current, pageSize })
  }

  const onTabChange = (activeKey: string) => {
    setCurrentTab(activeKey)
  }

  const handleCloseModal = () => {
    setShowPreview(false)
  }
  const handleOpenPreviewModal = () => {
    setShowPreview(true)
  }
  const handleQcDone = () => {
    handleCloseModal()
    setIsPreviewDone(true)
    updatePreviewStatus(true)
  }
  const getVideoUrl = () => {
    return batchForm.getFieldsValue().videoType === "youtube" ? batchForm.getFieldsValue().video_url : batchForm.getFieldsValue().video_url?.[0]
  }
  const onFormChange = () => {
    resetPreview()
    setIsPreviewDone(false)
  }

  /* const getStoreProductList = useCallback(async () => {
    if (!templeId) {
      return
    }
    const productListResponse = await monetisationOfferingsApi.storesApi.getProductList(
      templeId,
    )
    setProductList(productListResponse?.data?.data?.products)
  }, [monetisationOfferingsApi.storesApi, templeId])
  
  useEffect(() => {
    getStoreProductList()
  }, [getStoreProductList]) */

  const onCompleteChange = () => {
    setIsComplete(!isComplete)
  }
  const handleCompletedVideoPreview = (videoUrl: string, video_type: string, record: any) => {
    setCurrentCompletedVideo(videoUrl)
    setCompletedPoojaService(video_type === "youtube" ? true : false)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
    })
  }

  const rowSelectionChange = (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    setShowUploadButton(selectedRows?.length > 0)
    setRowSelection({
      ...rowSelection,
      selectedRows: [...selectedRows],
    })
  }

  const [rowSelection, setRowSelection] = useState<any>({
    selectedRows: [],
    onChange: rowSelectionChange
  })

  const handleClickUploadMedia = () => {
    setShowMediaUploadModal(true)
  }

  const handleUploadMediaMultiple = async () => {
    setLoading(true)
    try {
      await mediaUploadForm.validateFields()
      const mediaUploadFormData = mediaUploadForm.getFieldsValue()
      let payload = mediaUploadFormData?.order_media?.map((item: any) => ({
        type: mediaUploadFormData?.order_media_type,
        media_url: item,
      }))
      const formData = {
        order_media: payload,
      }

      const setDelay = (ms = 100) => new Promise((r) => setTimeout(r, ms))
      let failedBatches = []

      for (const row of rowSelection?.selectedRows) {
        try {
          await setDelay()
          await monetisationOfferingsApi.batchApi.uploadOrderMedia(row?.batch_id, formData)
          message.success({
            content: `Order Media updated for - ${row?.batch_code}`,
            duration: 3,
          })
        } catch (error) {
          failedBatches.push(row?.batch_id);
          message.error({
            content: `Error while updating batch - ${row?.batch_code}`,
            duration: 2,
          })
        }
      }
      setLoading(false)
      setShowMediaUploadModal(false)
      setRowSelection({ ...rowSelection, selectedRowKeys: [] })
      setShowUploadButton(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancelUploadMedia = () => {
    setShowMediaUploadModal(false)
  }

  const handleClickCreateDelivery = (record: any) => {
    createOrderForm?.resetFields()
    setSelectedRow(record)
    setShowCreateOrderForm(true)
  }

  const createDelivery = () => {
    createOrderForm
      .validateFields()
      .then(async (values: any) => {
        const payload = deliveryOrderPayload(values, selectedRow?.batch_id)
        try {
          const successDelivery = await monetisationOfferingsApi.batchApi.createDeliveryOrder(payload)
          setIsDeliveryOrderCreated(true)
          setShowCreateOrderForm(false)
          message.success(successDelivery?.data?.data, 2)
          getBatchList()
        } catch (error) {
          message.error('Error while creating Delivery Order Creation!', 2)
        }
      })
      .catch(error => {
        console.log('Create delivery order error')
      })
  }

  const handleStoreMediaModal = () => {
    fetchStoreDetails()
    setIsStoreMediaModalVisible(true);
  }
  const handleCloseStoreMediaModal = () => setIsStoreMediaModalVisible(false);

  const handleSubmitStoreMedia = async (values: any) => {
    try {
      const storeId = templeId
      const action = 'UPDATE_STORE_MEDIA'

      const introVideoURL = values.intro_video_url || null;
      const outroVideoURL = values.outro_video_url || null;

      const response = await monetisationOfferingsApi.storesApi.updateStoreMedia(storeId || '', action, introVideoURL, outroVideoURL, false);
      if (response?.data?.data) {
        message.success('Media updated successfully!');
        fetchStoreDetails();
        setIsStoreMediaModalVisible(false);
      } else {
        message.error('Failed to update media!');
      }
    } catch (error) {
      console.error('Error updating media:', error);
      message.error('An error occurred while updating media!');
    }
  };


  const handleClearStoreMedia = async () => {
    try {
      const storeId = templeId
      const action = 'UPDATE_STORE_MEDIA'

      const response = await monetisationOfferingsApi.storesApi.updateStoreMedia(storeId || '', action, null, null, true);
      if (response?.data?.data) {
        message.success('Media cleared successfully!');
        setIsStoreMediaModalVisible(false);
        fetchStoreDetails();
      } else {
        message.error('Failed to clear media!');
      }
    } catch (error) {
      console.error('Error clearing media:', error);
      message.error('An error occurred while clearing media!');
    }
  };

  const setStoreDetails = (data: any) => {
    setStoreDetailsState({
      intro_video_url: data?.intro_video_url || null,
      outro_video_url: data?.outro_video_url || null,
      ...data,
    });
  };


  const fetchStoreDetails = async () => {
    try {
      const storeId = templeId;

      const response = await monetisationOfferingsApi.storesApi.getStoreById(storeId || '', countryState.countryCode, languageState.monetisationLanguage);
      if (response?.data?.data) {
        setStoreDetails(response.data.data);
      } else {
        message.error('Failed to fetch store details');
      }
    } catch (error) {
      console.error('Error fetching store details:', error);
      message.error('An error occurred while fetching store details!');
    }
  };


  console.log({ currentBatchView })

  return (
    <div style={{ padding: '0 40px' }}>
      <Spin spinning={loading} delay={500} style={{ zIndex: 3000 }}>
        {
          errorPage ?
            <Error text='Error While fetching Batch List!' /> :
            <>
              <AdminBreadcrumb
                routes={getPageBreadcrumbs(searchParams.get('name') || '', hubId || '')}
              />
              <BatchListPage
                meta_data={metaMap}
                onSearch={onSearch}
                itemList={paginatedBatchData || []}
                onViewBatchClick={onViewBatchClick}
                generateBatch={generateBatch}
                handleTableChange={handleTableChange}
                pagination={pagination}
                total={totalRecord}
                skuFilters={skuFilters!}
                onTabChange={onTabChange}
                isComplete={isComplete}
                onCompleteChange={onCompleteChange}
                reducedItemCount={reducedItemNoCount}
                batchCount={batchData.length}
                handleCompletedVideoPreview={handleCompletedVideoPreview}
                languageSelected={languageState?.monetisationLanguage}
                isGenerateBatch={isGenerateBatch}
                handleClickUploadMedia={handleClickUploadMedia}
                showUploadButton={showUploadButton}
                selectionType={selectionType}
                rowSelection={rowSelection}
                handleCreateDelivery={handleClickCreateDelivery}
                isDeliveryOrderCreated={isDeliveryOrderCreated}
                hubAssociates={hubAssociates}
                updateAssociateForBatch={updateAssociateForBatch}
                handleStoreMediaModal={handleStoreMediaModal}
              />
            </>
        }
      </Spin>
      <BatchView
        visible={showbatchView}
        batchData={currentBatchView}
        onClose={closeHandler}
        handleNameListExport={handleNameListExport}
        handlePrayerExport={handlePrayerExport}
        currentStep={currentStep}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={YODA_PRESIGN_URL}
        form={batchForm}
        handleFormSubmit={handleFormSubmit}
        isPreviewDone={isPreviewDone}
        openPreview={openPreviewModal}
        onFormChange={onFormChange}
        handleOrderMediaFormSubmit={handleOrderMediaFormSubmit}
        orderMediaUploadForm={orderMediaForm}
      />
      <PreviewModal
        isPujaService={currentBatchView?.product_type === "pooja"}
        visible={showPreview}
        closeModal={handleCloseModal}
        handleDoneQc={handleQcDone}
        videoUrl={getVideoUrl()}
        batchData={currentBatchView}
        videoType={batchForm.getFieldsValue().videoType}
      />
      {
        <CompletedPreviewModal
          title={currentCompletedVideo?.split('/')?.pop() || ''}
          visible={!!currentCompletedVideo}
          closeModal={() => setCurrentCompletedVideo('')}
          handleDoneQc={() => { }}
          videoUrl={currentCompletedVideo}
          batchData={currentBatchView}
          okTitle={''}
          closeTitle={'Close'}
          isPujaService={isCompletedPoojaService}
        />
      }
      <UploadMediaModal
        visibleMediaUpload={showMediaUploadModal}
        handleOk={handleUploadMediaMultiple}
        handleCancel={handleCancelUploadMedia}
        mediaUploadForm={mediaUploadForm}
      />
      <CreateDeliveryOrder
        showItemCreation={showCreateOrderForm}
        closeItemCreation={() => setShowCreateOrderForm(false)}
        form={createOrderForm}
        initialValues={null}
        createDelivery={createDelivery}
      />
      <StoreMediaModal
        visible={isStoreMediaModalVisible}
        onClose={handleCloseStoreMediaModal}
        storeDetails={storeDetails}
        handleSubmitStoreMedia={handleSubmitStoreMedia}
        handleClearStoreMedia={handleClearStoreMedia}
      />
    </div>
  )
}

export default withDefaultLayout(BatchListScreen)
