import React, { useCallback, useEffect } from 'react'
import { Button, Col, Drawer, Form, Input, Row, Space, message } from 'antd'
import { useAppContext } from '@/components/AppContext';
import { getMonetisationPujaApi } from '@/utils/helper';
import { useForm } from 'antd/lib/form/Form'
import useFetch from '@/hooks/useFetch';
import { AddressPayload } from '@a4b/api/src/modules/Monetisation/Puja/types/address';
import { CustomLoader } from '@/components/Loader';
import EmptyState from '@/components/ui/EmptyState'
import { parseAddress, prepareAddressPayload } from '../../utils/helper';

interface Props {
    addressFormDrawer: { show: boolean, id: string | undefined }
    isEditJourney: boolean
    closeFormCallback: (refetch?: boolean) => void
}

const AddressForm: React.FC<Props> = ({
    addressFormDrawer,
    isEditJourney,
    closeFormCallback,
}) => {
    const [addressForm] = useForm()
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)

    const getAddressById = useCallback(async () => {
        if (!addressFormDrawer.show || !addressFormDrawer.id) {
            return { data: undefined }
        }
        try {
            const addressListRes = await monetisationPujaApi.addressesApi.getAddressById(addressFormDrawer.id)
            return {
                data: addressListRes?.data?.data
            }
        } catch (error) {
            console.error("Error fetching address:", error)
            return {
                err: "Failed to fetch address details",
            }
        }
    }, [monetisationPujaApi.addressesApi, addressFormDrawer.id, addressFormDrawer.show])

    const { data: address, error, loading } = useFetch(getAddressById)

    useEffect(() => {
        if (isEditJourney && address) {
            const formattedAddress = parseAddress(address)
            addressForm.setFieldsValue(formattedAddress)
        } else {
            addressForm.resetFields()
        }
    }, [addressForm, address, isEditJourney])

    const handleSubmit = async (values: AddressPayload) => {
        try {
            const formattedValues = prepareAddressPayload(values)
            console.log(formattedValues)

            if (isEditJourney && addressFormDrawer.id) {
                await monetisationPujaApi.addressesApi.updateAddress(addressFormDrawer.id, formattedValues)
            } else {
                await monetisationPujaApi.addressesApi.createAddress(formattedValues)
            }

            closeFormCallback(true)
        } catch (error) {
            console.error("Error submitting address form:", error)
            message.error("Error submitting address form")
        }
    }

    return (
        <Drawer
            title={`${isEditJourney ? "Update" : "Create"} Address`}
            placement='right'
            onClose={() => closeFormCallback()}
            visible={addressFormDrawer.show}
            destroyOnClose={true}
            size="large"
        >
            {loading ? (
                <Row justify='center'>
                    <CustomLoader />
                </Row>
            ) : error ? (
                <EmptyState errorText='No Address found!' />
            ) : (
                <Form
                    form={addressForm}
                    layout='vertical'
                    onFinish={handleSubmit}
                    initialValues={address}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label='Pickup person'
                                name='pickup_person'
                                rules={[{ required: true, message: 'Please enter Pickup person' }]}
                            >
                                <Input placeholder='Enter Pickup person' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label='Address'
                                name='address'
                                rules={[{ required: true, message: 'Please enter address' }]}
                            >
                                <Input.TextArea rows={3} placeholder='Enter full address' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Pickup Email (Optional)'
                                name='pickup_email'
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address'
                                    }
                                ]}
                            >
                                <Input placeholder='Enter email address' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Tag'
                                name='tag'
                                rules={[{ required: true, message: 'Please enter address tag' }]}
                            >
                                <Input placeholder='Enter Tag' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='City'
                                name='city'
                                rules={[{ required: true, message: 'Please enter city' }]}
                            >
                                <Input placeholder='Enter city' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='District'
                                name='district'
                                rules={[{ required: true, message: 'Please enter district' }]}
                            >
                                <Input placeholder='Enter district' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='State'
                                name='state'
                                rules={[{ required: true, message: 'Please enter state' }]}
                            >
                                <Input placeholder='Enter state' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Pincode'
                                name='pincode'
                                rules={[
                                    { required: true, message: 'Please enter pincode' },
                                    { pattern: /^\d{6}$/, message: 'Please enter a valid 6-digit pincode' }
                                ]}
                            >
                                <Input placeholder='Enter pincode' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Country'
                                name='country'
                                rules={[{ required: true, message: 'Please enter country' }]}
                            >
                                <Input placeholder='Enter country' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Phone'
                                name='phone'
                                rules={[
                                    { required: true, message: 'Please enter phone number' },
                                    { pattern: /^\d{10}$/, message: 'Please enter a valid 10-digit phone number' }
                                ]}
                            >
                                <Input placeholder='Enter phone number' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Landmark'
                                name='landmark'
                                rules={[{ required: true, message: 'Please enter landmark' }]}
                            >
                                <Input placeholder='Enter nearby landmark' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ marginTop: 24 }}>
                            <Space>
                                <Button onClick={() => closeFormCallback()}>
                                    Cancel
                                </Button>
                                <Button type='primary' htmlType="submit" loading={loading}>
                                    {isEditJourney ? "Update Address" : "Create Address"}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            )}
        </Drawer >
    )
}

export default AddressForm