import React from 'react'
import S3Upload from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload'
import { Button, Col, Drawer, Form, Input, Row, Select, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { PUJA_PRESIGN_URL, YODA_CDN_URL } from '@/utils/constants'

const { Option } = Select
interface Props {
  onClose: (fetchProducts: boolean) => void
  visible: boolean
  formSubmithandler: (values: any) => void
  form: any
  initialValues: any
  isEditJourney: boolean
}
const ItemCreation: React.FC<Props> = ({
  onClose,
  visible,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney
}) => {
  return (
    <Drawer
      title='Create New Product'
      placement='right'
      onClose={() => onClose(false)}
      visible={visible}
      destroyOnClose={true}
      width={600}
    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Name of the Product'
              name='title'
              rules={[{ required: true, message: 'Please input item name!' }]}
            >
              <Input placeholder='Enter Name of the item' />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label='Product price'
              name='price'
              rules={[{ required: true, message: 'Please input price of item!' }]}
            >
              <Input type='number' placeholder='Enter price' />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label='Description'
          name='description'
          rules={[{ required: true, message: 'Please input description!' }]}
        >
          <TextArea rows={4} placeholder='Enter description of the item' />
        </Form.Item>

        <Form.Item
          label='Product type'
          name='item_type'
          rules={[{ required: true, message: 'Please select item type!' }]}
        >
          <Select placeholder='Select type'>
            <Option value='item'>Item</Option>
            <Option value='prayer'>Prayer</Option>
            <Option value='pooja'>Pooja</Option>
            <Option value='sankalp'>Sankalp</Option>
          </Select>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Main Image'
              name='image_url'
              valuePropName='fileList'
              rules={[
                {
                  min: 1,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Main Image')
                    }
                  },
                },
              ]}
            >
              <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={PUJA_PRESIGN_URL} min={1} max={1} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label='Bottom sheet image'
              name='bottomsheet_image_url'
              valuePropName='fileList'
              rules={[
                {
                  min: 1,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Bottom sheet Image')
                    }
                  },
                },
              ]}
            >
              <S3Upload
                cdnUrl={YODA_CDN_URL}
                preSignUrl={PUJA_PRESIGN_URL}
                min={1}
                max={1}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label='Bottom Sheet Medias'
          name='bottomsheet_medias'
          style={{ marginTop: '15px' }}
          rules={[
            {
              required: true,
              min: 1,
              max: 10,
              validator: async (rules: any, values: any) => {
                if (values?.length < rules.min) {
                  return Promise.reject('Bottomsheet image')
                }
              },
            },
          ]}
          valuePropName='fileList'
        >
          <S3Upload
            cdnUrl={YODA_CDN_URL}
            preSignUrl={PUJA_PRESIGN_URL}
            min={1}
            max={10}
          />
        </Form.Item>

        {/* IGST Applicable Toggle */}
        <Form.Item
          label='Is IGST Applicable?'
          name='is_igst'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>


        {/* HSN Code */}
        <Form.Item
          label='HSN Code'
          name='hsn_code'
        // rules={[
        //   { pattern: /^[0-9]{6,8}$/, message: 'HSN Code must be 6 to 8 digits!' },
        // ]}
        >
          <Input placeholder='Enter HSN Code' maxLength={8} />
        </Form.Item>

        {/* Tax Rate Percentage */}
        <Form.Item
          label="Tax Rate"
          name="tax_rate"
          rules={[
            {
              validator: (_, value) => {
                if (value === undefined || value === null || value === "") {
                  return Promise.resolve(); // Required validation will handle empty values
                }

                const numValue = Number(value);
                if (isNaN(numValue) || numValue < 0 || numValue > 100) {
                  return Promise.reject("Tax Rate % must be a valid number between 0 and 100!");
                }

                return Promise.resolve();
              }
            }
          ]}
        >
          <Input type="number" placeholder="Enter Tax Rate %" />
        </Form.Item>

        <Form.Item style={{ paddingTop: 24 }}>
          <Button type='primary' onClick={formSubmithandler}>
            {isEditJourney ? 'Update Product' : "Create Product"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
export default ItemCreation
