import {
  formatDateToIsoStringTime,
  formatDateToIstIsoString,
  formatDateToMomentUtc,
  getFileFormat,
} from '@/utils/helper'

import { StoreData } from '../types'

export interface PujaMedia {
  media_type: string
  media_url: string
  media_description: string
  media_thumbnail: string
}

export interface PujaUpdateForm {
  puja_start_time: string
  puja_end_time: string
  puja_media: PujaMedia[]
  new_puja_state:
    | 'STARTED'
    | 'COMPLETED'
    | 'SCHEDULED'
    | 'BOOKED'
    | 'CANCELLED'
    | 'RESCHEDULED'
  message_cards: { header: string; description: string }[]
  priest_medias: PujaMedia[]
  scheduled_at: string
  init_scheduled_time: string
  change_status_immediately: boolean
}

export function parseStoreResponse(storeResponseData: StoreData) {
  return {
    ...storeResponseData,
    puja_start_time: storeResponseData?.puja_start_time
      ? formatDateToMomentUtc(storeResponseData?.puja_start_time)
      : undefined,
    puja_end_time: storeResponseData?.puja_end_time
      ? formatDateToMomentUtc(storeResponseData?.puja_end_time)
      : undefined,
    init_scheduled_time:
      storeResponseData?.is_scheduled === true &&
      storeResponseData?.init_scheduled_time
        ? formatDateToMomentUtc(
            storeResponseData?.init_scheduled_time,
            '+05:30',
          )
        : undefined,
    puja_media: storeResponseData?.puja_media?.map((media: any) => ({
      ...media,
      media_url: media?.media_url ? [media?.media_url] : undefined,
      media_thumbnail: media?.media_thumbnail
        ? [media?.media_thumbnail]
        : undefined,
    })),
    priest_medias: storeResponseData?.priest_medias?.map((media: any) => ({
      ...media,
      media_url: media?.media_url ? [media?.media_url] : undefined,
      media_thumbnail: media?.media_thumbnail
        ? [media?.media_thumbnail]
        : undefined,
    })),
    message_cards: storeResponseData?.message_cards?.map((media: any) => ({
      ...media,
      cta_media: {
        ...media?.cta_media,
        media_url:
          media?.cta_media?.media_type === 'url'
            ? media?.cta_media?.media_url
            : [media?.cta_media?.media_url],
        media_type: media?.cta_media?.media_type,
        media_thumbnail: media?.cta_media?.media_thumbnail
          ? [media?.media_thumbnail]
          : undefined,
      },
    })),
  }
}

export function preparePayload(data: PujaUpdateForm) {
  return {
    puja_start_time: data?.puja_start_time
      ? formatDateToIsoStringTime(data?.puja_start_time)
      : null,
    puja_end_time: data?.puja_end_time
      ? formatDateToIsoStringTime(data?.puja_end_time)
      : null,
    ...(!data?.change_status_immediately && {
      scheduled_at: data?.init_scheduled_time
        ? formatDateToIstIsoString(data?.init_scheduled_time)
        : null,
    }),
    is_scheduled: !data?.change_status_immediately,
    puja_media:
      data?.puja_media?.length > 0
        ? data?.puja_media?.map((item: any) => {
            const fileUrl = item?.media_url?.[0]
            const fileExtension = fileUrl?.split?.('.')?.pop?.()
            const mediaType = getFileFormat(fileExtension)
            return {
              ...item,
              media_type: mediaType,
              media_url: fileUrl,
              media_thumbnail: item?.media_thumbnail?.[0],
            }
          })
        : null,
    new_puja_state: data?.new_puja_state,
    message_cards:
      data?.message_cards?.length > 0
        ? data?.message_cards?.map((item: any) => {
            let fileUrl, fileExtension, mediaType
            if (item?.cta_media?.mediaType !== 'url') {
              fileUrl = item?.cta_media?.media_url?.[0]
              fileExtension = fileUrl?.split?.('.')?.pop?.()
              mediaType = getFileFormat(fileExtension)
            }
            return {
              ...item,
              cta_media: {
                ...item?.cta_media,
                media_type:
                  item?.cta_media?.media_type === 'url' ? 'url' : mediaType,
                media_url:
                  item?.cta_media?.media_type === 'url'
                    ? item?.cta_media?.media_url
                    : item?.cta_media?.media_url?.[0],
                media_thumbnail: item?.cta_media?.media_thumbnail?.[0],
              },
            }
          })
        : null,
    priest_medias:
      data?.priest_medias?.length > 0
        ? data?.priest_medias?.map((item: any) => {
            const fileUrl = item?.media_url?.[0]
            const fileExtension = fileUrl?.split?.('.')?.pop?.()
            const mediaType = getFileFormat(fileExtension)
            return {
              ...item,
              media_type: mediaType,
              media_url: fileUrl,
              media_thumbnail: item?.media_thumbnail?.[0],
            }
          })
        : null,
  }
}
