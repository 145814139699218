import React from 'react';
import {
    Button,
    Card,
    Col,
    Image,
    Row,
    Tag,
    Typography,
} from 'antd';

import { Product } from '@a4b/api/src/modules/Monetisation/Puja/types';

const { Title, Text } = Typography;

interface Props {
    formItems: Product;
    added: (item: Product) => void;
}

const PujaAddonItem: React.FC<Props> = ({ formItems, added }) => (
    <Card
        style={{ width: '100%', height: "100%", borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', marginBottom: '10px' }}
        key={`${formItems.id}-view`}
    >
        <Row gutter={16} align="middle">
            <Col span={4}>
                <Image
                    src={formItems.image_url}
                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                />
            </Col>
            <Col span={12}>
                <Title level={5} style={{ marginBottom: '4px' }}>
                    {formItems.title}
                </Title>
                <span
                    style={{
                        fontSize: '14px',
                        display: '-webkit-box',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {formItems.description}
                </span>
            </Col>
            <Col span={4}>
                <Tag color="blue" style={{ fontSize: '14px' }}>
                    {formItems?.item_type}
                </Tag>
                <Text strong style={{ fontSize: '16px', display: 'block', marginTop: '8px' }}>
                    ₹{formItems.price}
                </Text>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
                <Button
                    type="primary"
                    onClick={() => added(formItems)}
                    size="small"
                    style={{ width: '100%' }}
                >
                    Add
                </Button>
            </Col>
        </Row>
    </Card>
);

export default PujaAddonItem;
