import { useAppContext } from '@/components/AppContext'
import { link, NavMenuItem } from '@/utils/types'
import { Avatar, Dropdown, Layout, Menu } from 'antd'
import styled from 'styled-components'
import { LoginState } from '@/entities/UserProfile'
import React from 'react'
import { Link } from 'react-router-dom'
import { logoutUser } from '../../../utils/helper'

interface Props {
  menu: NavMenuItem[]
  selectedKey: string
  loggedIn?: boolean
}

const { SubMenu, Item } = Menu

const { Header } = Layout

const Container = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
  position: fixed;
  z-index: 1;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const TopHeader: React.FC<Props> = ({
  menu,
  selectedKey,
  loggedIn = false,
}) => {
  const profileMenu = (
    <Menu>
      <Menu.Item>
        <a target="#" onClick={logoutUser}>
          Logout
        </a>
      </Menu.Item>

    </Menu>
  );
  const { userProfile } = useAppContext()

  return (
    <Container>
      <LogoContainer>
        <Avatar src='https://appsforbharat.com/assets/img/favicon.ico' />
      </LogoContainer>

      {userProfile?.loginStatus === LoginState?.LOGGED_IN && (
        <Menu
          mode="horizontal"
          theme="light"
          style={{ flexGrow: 1, padding: '0 5px', overflow: 'auto' }}
          selectedKeys={[selectedKey]}
        >
          {menu?.map((item, index) => (
            <SubMenu title={item?.title} key={item?.id} icon={item?.icon}>
              {/* First section: item.linkItem handling */}
              {item?.linkItem &&
                item?.linkItem?.map(menuItem => {
                  if (menuItem.children) {
                    return menuItem?.children.map(childItem => (
                      <SubMenu title={childItem?.title} key={childItem?.id}>
                        {childItem?.linkItem &&
                          childItem?.linkItem.map(menuItem => (
                            <Item key={menuItem?.id}>
                              <Link to={menuItem?.path}>{menuItem?.name}</Link>
                            </Item>
                          ))}
                      </SubMenu>
                    ))
                  }
                  return (
                    <Item key={menuItem?.id}>
                      <Link to={menuItem?.path}>{menuItem?.name}</Link>
                    </Item>
                  )
                })}

              {/* Second section: item.children handling */}
              {item?.children &&
                item?.children.map(childItem => (
                  <SubMenu title={childItem?.title} key={childItem?.id}>
                    {childItem?.linkItem &&
                      childItem?.linkItem.map(menuItem => {
                        if (menuItem.children) {
                          return menuItem?.children.map(childItem => (
                            <SubMenu title={childItem?.title} key={childItem?.id}>
                              {childItem?.linkItem &&
                                childItem?.linkItem.map(menuItem => (
                                  <Item key={menuItem?.id}>
                                    <Link to={menuItem?.path}>{menuItem?.name}</Link>
                                  </Item>
                                ))}
                            </SubMenu>
                          ))
                        }
                        return (
                          <Item key={menuItem?.id}>
                            <Link to={menuItem?.path}>{menuItem?.name}</Link>
                          </Item>
                        )
                      })}
                  </SubMenu>
                ))}
            </SubMenu>
          ))}
        </Menu>
      )}
      <Menu
        mode='horizontal'
        style={{
          lineHeight: '62px',
          margin: '0 0px',
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      ></Menu>

      {userProfile?.email ? (
        <Dropdown overlay={profileMenu} placement="bottomLeft">
          <span style={{
            width: '100px',
            height: '64px',
            overflow: 'hidden'
          }}>
            <Avatar style={{ marginRight: '10px' }} src={userProfile?.imageUrl} />
            {userProfile?.name}
          </span>
        </Dropdown>

      ) : (
        <div
          style={{ position: 'relative', zIndex: 10 }}
          id='loginButton'
        ></div>
      )}
    </Container>
  )
}

export default TopHeader
