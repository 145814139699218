import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { Product } from '@a4b/api/src/modules/Monetisation/Puja/types'
import CreateItem from './components/CreateItem'
import { A4B_APPS } from '@/utils/constants'
import ManageItemsPage from './components/ManageItemsPage'


const ManageItemScreen: React.FC = () => {
  const { networkInstance, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
  const isSrimandir = app === A4B_APPS.SRIMANDIR
  const [showItemCreation, setShowItemCreation] = React.useState(false)
  const [isEditJourney, setIsEditJourney] = React.useState(false)
  const [productList, setProductList] = useState<Product[]>([])
  const [product, setProduct] = useState<Product>()
  const rowSelectionChange = (selectedRowKeys: any) => {
    setRowSelectionConfig({
      ...rowSelectionConfig,
      selectedRowKeys: [...selectedRowKeys],
    })
  }

  const [rowSelectionConfig, setRowSelectionConfig] = useState<any>({
    selectedRowKeys: [],
    onChange: rowSelectionChange,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const getPageBreadcrumbs = useMemo(() => [
    {
      path: isSrimandir ? '/monetisation/puja' : '/astro/puja',
      breadcrumbName: 'Hub List',
    },
    {
      path: isSrimandir ? '/monetisation/puja/items' : '/astro/puja/items',
      breadcrumbName: 'Manage Products',
    },
  ], [isSrimandir])

  const getProductList = useCallback(async () => {
    try {
      const productList = await monetisationPujaApi.productApi.getProductList({
        limit: 500,
        offset: 0,
      })

      setProductList(productList?.data?.data)
    } catch (error) {
      message.error({
        content: 'Error while fetching product list',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationPujaApi.productApi])

  useEffect(() => {
    getProductList()
  }, [getProductList])

  useEffect(() => {
    searchParams.get('isCreateFlow') === "true" ? setShowItemCreation(true) : setShowItemCreation(false)
  }, [])

  const onCreateNew = () => {
    setShowItemCreation(true)
    setSearchParams(
      createSearchParams({
        isCreateFlow: "true"
      })
    )
    setIsEditJourney(false)
    setProduct(undefined)
  }

  const showItemForm = () => {
    setShowItemCreation(true)
  }

  const closeItemCreationForm = (fetchProductList = false) => {
    setShowItemCreation(false)
    setSearchParams(
      createSearchParams({
        isCreateFlow: "false"
      })
    )
    isEditJourney && setIsEditJourney(false)
    setProduct(undefined)

    if (fetchProductList) {
      getProductList()
    }
  }

  const onStatusChange = (status: boolean) => { }

  const triggerEdit = (temple: Product) => {
    setProduct(temple)
    setIsEditJourney(true)
    setShowItemCreation(true)
  }



  return (
    <div style={{ padding: '0 40px' }}>
      <AdminBreadcrumb routes={getPageBreadcrumbs} />
      <ManageItemsPage
        onCreateNew={onCreateNew}
        onStatusChange={onStatusChange}
        triggerEdit={triggerEdit}
        dataSource={productList}
        rowSelectionConfig={rowSelectionConfig}
      />
      {showItemCreation &&
        <CreateItem
          showItemCreation={showItemCreation}
          closeItemCreation={closeItemCreationForm}
          isEditJourney={isEditJourney}
          formData={product}
        />
      }
    </div>
  )
}

export default withDefaultLayout(ManageItemScreen)
