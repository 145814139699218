import { WarningOutlined } from "@ant-design/icons";
import { Typography } from "antd";

const { Title } = Typography;
const EmptyState = ({ errorText }: { errorText?: string }) => {
    return (<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '60px 40px' }}>
        <Title level={3}>
            <WarningOutlined />
            <span style={{ marginLeft: '10px' }}>
                {
                    errorText ? errorText : 'No data found!'
                }
            </span>
        </Title>
    </div>);
}

export default EmptyState;