
import { CopyOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Card, Col, message, Row, Space, Switch, Table } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import styled from 'styled-components';

import React from 'react';

import {
  ManageSectionItem
} from '@a4b/api/src/modules/Monetisation/Puja/types';
import { copyToClipboard } from '@/utils/helper';

interface Props {
  onCreateNew: () => void
  triggerEdit: (storeCard: ManageSectionItem) => void
  dataSource: ManageSectionItem[]
  onStatusChange: (storeCard: ManageSectionItem) => void
  paginationParams: TablePaginationConfig
  handleTableChange: (paginationParams: TablePaginationConfig) => void
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const SectionList: React.FC<Props> = ({
  onCreateNew,
  triggerEdit,
  dataSource,
  onStatusChange,
  paginationParams,
  handleTableChange
}) => {
  const dataColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '25%',
      ellipsis: true,
      render(value: any, record: any, index: any) {
        const handleCopy = async () => {
          const success = await copyToClipboard(value);
          if (success) {
            message.success('Copied to clipboard!');
          } else {
            message.error('Failed to copy!');
          }
        };

        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span>{value}</span>
            <CopyOutlined onClick={handleCopy} style={{ cursor: 'pointer' }} />
          </div>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: 'Section Type',
      dataIndex: 'section_type',
      key: 'section_type',
      width: '15%',
    },
    {
      title: 'Item Type',
      dataIndex: 'item_type',
      key: 'item_type',
      width: '15%',
    },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (text: any, record: any, index: any) => {
        return (
          <Row>
            <Col span={10}>
              <Switch
                checked={record.active}
                onChange={() => onStatusChange(record)}
              />
            </Col>
            <Col span={8}>
              <Button onClick={() => triggerEdit(record)} type='link'>Edit</Button>
            </Col>
          </Row>
        )
      },
    },
  ]
  return (
    <Container style={{ width: '100%' }}>
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <BodyContainer>
        <Button type='text' onClick={onCreateNew}>
          <PlusSquareOutlined /> Add New Sections
        </Button>

        <Table
          style={{ width: "100%" }}
          columns={dataColumns}
          dataSource={dataSource}
          pagination={paginationParams}
          onChange={handleTableChange}
        />
      </BodyContainer>
    </Container>
  )
}

export default SectionList
