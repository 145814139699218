import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Input, Modal, Row,
    Select,
    Space,
    Spin,
    Switch,
    Table,
    Tag,
    Tooltip
} from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { CreateStore, HubListItem } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { CustomLoader } from '@/components/Loader'
import { useAppContext } from '@/components/AppContext'
import { A4B_APPS } from '@/utils/constants'

interface Props {
    templeList: CreateStore[]
    onStatusChange: (item: CreateStore) => void
    archiveStore: (storeId: string) => void
    unarchiveStore: (storeId: string) => void
    cloneStore: (storeId: string, hubId: string) => Promise<void>
    activeCloneId: string | undefined
    sortBy: 'created_at' | 'position',
    isArchive?: 'true' | 'false',
    isActive?: 'true' | 'false',
    updateFilters: (updateType: 'sortBy' | 'isArchive' | 'isActive' | 'serviceType', value?: 'true' | 'false' | 'created_at' | 'position' | 'pooja') => void
    loading: boolean
    hubList: HubListItem[]
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const Label = styled.span`
  font-size: 14px;
  padding-left: 10px;
`
enum MODAL_TYPE {
    CLONE = 'Clone',
    ARCHIVE = "Archive",
    UNARCHIVE = "Unarchive"
}
const StoreListTable: React.FC<Props> = ({
    templeList,
    onStatusChange,
    unarchiveStore,
    archiveStore,
    cloneStore,
    activeCloneId,
    sortBy,
    isArchive,
    isActive,
    updateFilters,
    loading,
    hubList
}) => {
    const { app } = useAppContext()
    const isSrimandir = app === A4B_APPS.SRIMANDIR
    const [dataSource, setDataSource] = useState(templeList);
    const [title, setTitle] = useState('')
    const [id, setId] = useState('')
    const [modelConfig, setModelConfig] = useState<{ type: MODAL_TYPE, id: string, hubId?: string, callback: (id: string, hubId: string) => void }>()

    useEffect(() => { setDataSource(templeList) }, [templeList])

    const filterSearchTitleInput = () => {
        return <Input
            placeholder="Search Title"
            value={title}
            onChange={e => {
                const currValue = e.target.value;
                setTitle(currValue);
                const filteredData = templeList.filter(entry =>
                    entry.title.toLowerCase().includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
            allowClear
        />
    }
    const filterSearchIdInput = () => {
        return <Input
            placeholder="Search ID"
            value={id}
            onChange={e => {
                const currValue = e.target.value
                setId(currValue)
                const filteredData = templeList.filter(entry =>
                    entry.id.toLowerCase().includes(currValue.toLowerCase())
                )
                setDataSource(filteredData)
            }}
            allowClear
        />
    }

    const dataColumns = [
        {
            title: <div style={{ display: 'flex', flexDirection: 'column' }}> <span>Title</span> <div>{filterSearchTitleInput()}</div></div>,
            dataIndex: 'title',
            key: 'no_of_items',
            width: '28%',
        },
        {
            title: <div style={{ display: 'flex', flexDirection: 'column' }}> <span>Store Id</span> <div>{filterSearchIdInput()}</div></div>,
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ellipsis: true,
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'No of Items',
            dataIndex: 'no_of_items',
            key: 'no_of_items',
            width: '10%',
            render: (text: string) => <span>{text}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (text: string, record: any) => {
                return (
                    <span>
                        <Label>{record.active ? <Tag color='green'>Active</Tag> : <Tag color='red'>In Active</Tag>}</Label>
                    </span>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            width: '30%',
            render: (text: any, record: any, index: any) => {
                return (
                    <Row style={{ alignItems: 'center' }}>
                        <Col span={5}>
                            <Switch
                                checked={record.active}
                                onChange={() => onStatusChange(record)}
                            />
                        </Col>
                        <Col span={4}>
                            <Link
                                to={isSrimandir ? `/monetisation/puja/temple/create?edit=true&store_id=${record.id}` :
                                    `/astro/puja/temple/create?edit=true&store_id=${record.id}`
                                }
                            >
                                <Button type='link'>Edit</Button>
                            </Link>
                        </Col>

                        <Col span={6}>
                            {
                                record.archived ?
                                    <Button onClick={() => setModelConfig({ type: MODAL_TYPE.UNARCHIVE, id: record.id, hubId: undefined, callback: unarchiveStore })} type='link'> Unarchive </Button>
                                    :
                                    <Button onClick={() => setModelConfig({ type: MODAL_TYPE.ARCHIVE, id: record.id, hubId: undefined, callback: archiveStore })} type='link' > Archive </Button>
                            }
                        </Col>
                        <Col span={4}>
                            <Button size='small' onClick={() => setModelConfig({ type: MODAL_TYPE.CLONE, id: record.id, hubId: '', callback: cloneStore })} loading={activeCloneId === record.id} > Clone </Button>
                        </Col>
                    </Row>
                )
            },
        },
    ]

    return (
        <Container
            title='Manage Stores'
            style={{ width: '100%' }}
            extra={
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col>
                        <label>Filter by: </label>
                        <Select placeholder="Is archived" allowClear value={isArchive} onChange={(value) => { updateFilters('isArchive', value) }} style={{ width: '120px', paddingLeft: '10px' }}>
                            <Select.Option value="true">Archived</Select.Option>
                            <Select.Option value="false">Unarchived</Select.Option>
                        </Select>
                        <Select placeholder="Status" value={isActive} onChange={(value: any) => { updateFilters('isActive', value) }} style={{ width: '120px', paddingLeft: '10px' }}>
                            <Select.Option value="true">Active</Select.Option>
                            <Select.Option value="false">Inactive</Select.Option>
                        </Select>
                    </Col>
                    <Col>
                        <label style={{ paddingLeft: '10px' }}>Sort by: </label>
                        <Select value={sortBy} onChange={(value) => { updateFilters('sortBy', value) }} style={{ width: '120px' }}>
                            <Select.Option value="created_at">Created at</Select.Option>
                            <Select.Option value="active">Active</Select.Option>
                            <Select.Option value="position">Position</Select.Option>
                        </Select>
                    </Col>
                    <Col style={{ paddingLeft: '10px' }}>
                        <Button type='primary'>
                            <Link to={isSrimandir ? '/monetisation/puja/temple/create' : '/astro/puja/temple/create'}>
                                <PlusSquareOutlined /> Add new Store
                            </Link>
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            {
                modelConfig &&
                <Modal
                    title={modelConfig.type}
                    visible={true}
                    onOk={() => {
                        modelConfig.callback(modelConfig.id, modelConfig.hubId || '')
                        setModelConfig(undefined)
                    }}
                    onCancel={() => { setModelConfig(undefined) }}

                >
                    <p style={{ textAlign: "center" }}>
                        <b>
                            {
                                modelConfig.type === MODAL_TYPE.CLONE ?
                                    "Are you sure you want to Clone ?" : modelConfig.type === MODAL_TYPE.ARCHIVE ?
                                        "Are you sure you want to Archive ?" :
                                        "Are you sure you want to Unarchive ?"
                            }
                        </b></p>
                    {
                        modelConfig.hubId !== undefined &&
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <p>
                                Please choose the hub ,for which you want to clone this store
                            </p>
                            <Select onChange={(id) => setModelConfig({ ...modelConfig, hubId: id })} aria-required style={{ width: '350px' }} placeholder="Select hub" >
                                {
                                    hubList.map((hub: HubListItem) => (
                                        <Select.Option key={hub.id} value={hub.id}>{hub.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                </Modal>
            }
            <Row>
                <Col span={28}>
                    <Table
                        sticky={true}
                        rowKey={(record: CreateStore) => record?.id}
                        columns={dataColumns}
                        dataSource={dataSource}
                        pagination={false}
                        loading={{
                            spinning: loading,
                            indicator: <Spin indicator={<CustomLoader />} />,
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default StoreListTable