import { LinkOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Row, Tag, Typography } from "antd"
import {
    ManageSectionItem,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'

interface ShowSectionInfoProps {
    data?: Partial<ManageSectionItem>
}

const SectionInfo: React.FC<ShowSectionInfoProps> = ({ data }) => {
    return (
        <Card
            style={{ width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', marginBottom: '10px' }}
        >
            <Row align="middle" justify="space-between">
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={12} style={{ paddingRight: '8px' }}>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: '14px',
                                display: 'block',
                                width: '100%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                            title={data?.title || 'N/A'}
                        >
                            {data?.title || 'N/A'}
                        </Typography.Text>
                    </Col>
                    <span>|</span>
                    <Col span={12} style={{ paddingLeft: '8px' }}>
                        <Typography.Text
                            style={{
                                fontSize: '14px',
                                display: 'block',
                                width: '100%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                            title={data?.description || 'N/A'}
                        >
                            {data?.description || 'N/A'}
                        </Typography.Text>
                    </Col>
                </Col>

                <Divider type="vertical" style={{ height: '30px', margin: '0 8px' }} />

                <Col span={11}>
                    <Row justify="space-between" align="middle">
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <Tag color="#108ee9">{data?.section_type || 'N/A'}</Tag>
                        </Col>

                        <Col span={8} style={{ textAlign: 'center' }}>
                            <Tag color="default">{data?.item_type || 'N/A'}</Tag>
                        </Col>

                        <Col span={8} style={{ textAlign: 'center' }}>
                            <Button
                                type="link"
                                icon={<LinkOutlined />}
                                onClick={() =>
                                    window.open(
                                        `/monetisation/puja/manage_page_section?current_tab=sections&section_id=${data?.id}`,
                                        '_blank'
                                    )
                                }
                            >
                                Section
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default SectionInfo