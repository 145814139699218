import { useAppContext } from "@/components/AppContext";
import useFetch from "@/hooks/useFetch";
import { getMonetisationPujaApi } from "@/utils/helper";
import { Address } from "@a4b/api/src/modules/Monetisation/Puja/types/address";
import { Form, Select } from "antd";
import React, { useCallback } from "react";

interface SelectPickupAddressProps {
    formItemName: string
    isRequired?: boolean
}
const SelectPickupAddress: React.FC<SelectPickupAddressProps> = ({ formItemName, isRequired = false }) => {
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)

    const getAddressList = useCallback(async () => {
        try {
            const addressListRes = await monetisationPujaApi.addressesApi.getAllAddress()
            return {
                data: addressListRes?.data?.data
            }
        } catch (error) {
            console.log("Error: ", error)
            return {
                err: "Failed to fetch adresses",
            }
        }
    }, [monetisationPujaApi.addressesApi])

    const { data: addressList, error: addressListError, loading: isLoadingAddressList } = useFetch(getAddressList)

    console.log({ addressList })

    return (
        <Form.Item
            label='Select Pickup Address'
            name={formItemName}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: isRequired, message: 'Please select pickup address!' }]}
        // style={{ marginTop: '20px' }}
        >
            <Select placeholder='Select pickup address' loading={isLoadingAddressList}>
                {addressList?.addresses?.map((address: Address) => (
                    <Select.Option value={address.id} key={address.id}>
                        {address.tag}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export default React.memo(SelectPickupAddress);