import { CustomLoader } from "@/components/Loader";
import { Address, AddressData } from "@a4b/api/src/modules/Monetisation/Puja/types/address";
import { Button, Modal, Row, Spin, TableProps, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import type { ColumnsType } from 'antd/es/table';

const useTableConfig = (
    addressList: AddressData,
    isLoading: boolean,
    triggerEdit: (record: any) => void
): TableProps<Address> => {
    const [dataSource, setDataSource] = useState(addressList?.addresses)

    useEffect(() => { setDataSource(addressList?.addresses) }, [addressList?.addresses])

    const AddressSubTableView = ({ items, title }: any) => {
        const [visible, setVisible] = useState(false);

        const showSKUModal = () => {
            setVisible(true)
        }

        const handleCancel = () => {
            setVisible(false)
        }

        return (
            <div style={{ width: '100%' }}>
                <Button type="default" onClick={showSKUModal}>{title}</Button>
                <Modal
                    title={title}
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Row justify="start">
                        <span>
                            {items?.address}
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                            |
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                            {items?.pincode}
                        </span>
                    </Row>
                </Modal>
            </div>
        )
    }

    const dataColumns: ColumnsType<Address> = useMemo(() => [
        {
            title: 'ID',
            key: 'id',
            width: '30%',
            dataIndex: 'id',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'Pickup person',
            width: '30%',
            key: 'pickup_person',
            dataIndex: 'pickup_person'
        },
        {
            title: 'Tag',
            width: '30%',
            key: 'tag',
            dataIndex: 'tag'
        },
        {
            title: 'Location',
            width: '40%',
            key: 'city',
            dataIndex: 'city',
            render: (text: any, record: any, index: any) => {
                return (<Row style={{ width: '100%' }}>
                    <span>
                        {record?.city}
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                        |
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                        {record?.state}
                    </span>
                </Row>
                )
            }
        },
        {
            title: 'Pickup email',
            width: '35%',
            key: 'pickup_email',
            dataIndex: 'pickup_email',
            render: (email: string) => (
                <span style={{ color: '#1677ff' }}>{email}</span>),
        },
        {
            title: 'Phone',
            width: '35%',
            key: 'phone',
            dataIndex: 'phone'
        },
        {
            title: 'Address',
            key: 'address',
            width: '35%',
            dataIndex: 'address',
            render: (text: string, record: any) => (
                <AddressSubTableView items={record} title='Address' />
            ),
        },
        {
            title: 'Landmark',
            width: '35%',
            key: 'landmark',
            dataIndex: 'landmark'
        },
        {
            title: 'Country',
            width: '20%',
            key: 'country',
            dataIndex: 'country'
        },
        {
            title: 'Edit Action',
            key: 'action',
            width: '15%',
            fixed: 'right',
            render: (text: any, record: any, index: any) => {
                return (
                    <Button type='link' onClick={() => triggerEdit(record)}>
                        Edit
                    </Button>
                )
            },
        },
    ], [triggerEdit])

    return {
        pagination: false, dataSource: dataSource, columns: dataColumns, loading: {
            spinning: isLoading,
            indicator: <Spin indicator={<CustomLoader />} />,
        }, size: 'middle', scroll: { x: 2000 }
    }
}

export default useTableConfig