import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { AddressPayload, ShipmentData } from './types/shipments'

class AddressesApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getShipments = (storeId: string) =>
    this.api.get<ShipmentData>(
      `/gw2/puja/internal/v1/shipments?store_id=${storeId}`,
    )

  createClickPostGenerate = (shipmentId: string, payload: AddressPayload) =>
    this.api.post(
      `/gw2/puja/internal/v1/shipments/trigger-shipment/${shipmentId}`,
      payload,
    )
}

export default AddressesApi
