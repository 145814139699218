import { Order } from '@/../../../packages/a4b-api/src/modules/Monetisation/Offerings/types';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';

export interface Props {
    orderData: Order
    onClose: () => void
    showModal: boolean
}

export const OrderDetailsModal: React.FC<Props> = ({
    orderData,
    onClose,
    showModal
}) => {
    const [expandedFields, setExpandedFields] = useState<{ [key: string]: boolean }>({});

    const toggleExpand = (key: string) => {
        setExpandedFields(prev => ({ ...prev, [key]: !prev[key] }));
    };

    return (
        <>
            <Modal
                title={`Additional Info ${orderData?.booking_id ? ` - Booking ID: ${orderData.booking_id}` : ''}`}
                visible={showModal}
                onCancel={onClose}
                footer={null}
                maskClosable={false}
                width='50vw'
                bodyStyle={{ overflowY: 'auto', height: '70vh' }}
            >
                <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#f5f5f5' }}>
                            <th style={{ padding: '8px', border: '1px solid #ddd', width: '40%' }}>Field</th>
                            <th style={{ padding: '8px', border: '1px solid #ddd', width: '60%' }}>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(orderData || {}).map(([key, value]) => {
                            let displayValue = typeof value === 'object' && value !== null
                                ? JSON.stringify(value, null, 2)
                                : String(value);

                            const isExpanded = expandedFields[key];
                            return (
                                <tr key={key}>
                                    <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                                        {key.replace(/_/g, ' ')}
                                    </td>
                                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                        {typeof value === 'boolean' ? (
                                            value ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />
                                        ) : (
                                            <>
                                                {isExpanded ? displayValue : `${displayValue.slice(0, 70)} `}
                                                {displayValue.length > 70 && (
                                                    <Button
                                                        type='link'
                                                        color='cyan'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => toggleExpand(key)}
                                                    >
                                                        {isExpanded ? '  view less' : '...view more'}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Modal>
        </>
    );
};