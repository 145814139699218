import {
  formatDateToIsoString,
  getFileFormat,
  getMediaExtension,
} from '@/utils/helper'
import moment from 'moment'

import {
  CreateInventory,
  CreateStore,
  LanguageState,
  Product,
  ProductData,
  StoreSessionDay,
} from '@a4b/api/src/modules/Monetisation/Puja/types'

import { CreateInventoryPayload } from '../../types'

export const formatCreateTempleApi = (
  formData: any,
  languageState: any,
  isActive: boolean,
  isEditJourney: boolean,
  countryCode: string,
): CreateStore => {
  const closedInfoMedia = formData?.closed_info_media_image.map(
    (item: any) => ({
      media_url: item,
      type: 'image',
    }),
  )
  if (formData?.closed_info_media_video?.length) {
    closedInfoMedia.unshift({
      media_url: formData?.closed_info_media_video?.[0],
      type: 'video',
    })
  }

  const infoMedia = formData?.info_media_image.map((item: any) => ({
    media_url: item,
    type: 'image',
  }))
  if (formData?.info_media_video?.length) {
    infoMedia.unshift({
      media_url: formData?.info_media_video?.[0],
      type: 'video',
    })
  }

  const slug: string = formData?.slug?.replace(/\s+/g, '-')

  const formObject: CreateStore = {
    slug: slug,
    title: formData?.title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    hub_id: formData?.hub_id,
    icon_url: formData?.icon_url[0],
    cover_media: {
      media_url: formData?.media_url[0],
      type: formData?.coverMediaType,
      media_thumbnail: formData?.thumbnail?.[0] ?? null,
    },
    info_media: infoMedia,
    closed_cover_media: {
      media_url: formData?.closed_media_url[0],
      type: formData?.closedCoverMediaType,
    },
    closed_info_media: closedInfoMedia,
    city: formData?.city,
    state: formData?.state,
    id: formData?.id,
    active: isActive,
    service_type: formData?.service_type,
    language_code: languageState.monetisationLanguage,
    location_details: {
      temple_details: {
        name: formData?.location_details?.temple_details?.name,
        location: formData?.location_details?.temple_details?.location,
        description: formData?.location_details?.temple_details?.description,
        image: formData?.location_details?.temple_details?.image?.[0],
      },
      pandit_details: {
        name: formData?.location_details?.pandit_details?.name,
        location: formData?.location_details?.pandit_details?.location,
        description: formData?.location_details?.pandit_details?.description,
        image: formData?.location_details?.pandit_details?.image?.[0],
      },
      show_location_details: formData?.location_details?.show_location_details,
    },
    country_code: countryCode,
    astro_pooja_list: formData?.astro_pooja_list?.length
      ? formData?.astro_pooja_list
      : null,
    tags: formData?.tags?.length ? formData?.tags : null,
    show_reorder_card_l2: formData?.show_reorder_card_l2 ? true : false,
    metadata: {
      pooja_specificity: formData?.pooja_specificity,
      pooja_usecase: formData?.pooja_usecase,
      pooja_title: formData?.pooja_title,
    },
    puja_faq: formData?.enable_puja_faq ? formData?.puja_faq : null,
    mantra_data: formData?.enable_mantra_data
      ? {
          ...formData?.mantra_data,
          icon: formData?.mantra_data?.icon?.[0],
        }
      : null,
    schedule_date: formData?.schedule_date
      ? formatDateToIsoString(formData?.schedule_date)
      : null,
    hindi_calendar_date: formData?.hindi_calendar_date,
    ...(formData?.pickup_address && {
      pickup_address: formData?.pickup_address,
    }),
    sku_code: formData?.sku_code,
  }
  if (formData?.whatsapp_share_media?.[0]) {
    formObject['whatsapp_share_media'] = {
      media_url: formData?.whatsapp_share_media?.[0],
      type: formData?.whatsappMediaType,
    }
  } else {
    formObject['whatsapp_share_media'] = { media_url: '', type: '' }
  }
  return formObject
}
export const parseCreateTempleApiEdit = (
  formData: any,
  languageState: LanguageState,
): CreateStore => {
  const infoMedia = formData?.info_media
    ?.map((item: any) => item.type === 'image' && item.media_url)
    .filter((item: string) => item)

  const video = formData?.info_media.filter(
    (item: any) => item?.type === 'video',
  )
  const closedInfoMedia = formData?.closed_info_media
    ?.map((item: any) => item.type === 'image' && item.media_url)
    .filter((item: string) => item)

  const closedVideo = formData?.closed_info_media?.filter(
    (item: any) => item?.type === 'video',
  )
  const slug: string = formData?.slug?.replace(/\s+/g, '-')
  const formObject: any = {
    store_id: formData?.store_id,
    slug: slug,
    title: formData?.title,
    display_title: formData?.display_title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    hub_id: formData?.hub_id,
    icon_url: [formData?.icon_url],
    media_url: [formData?.cover_media?.media_url],
    coverMediaType: formData?.cover_media?.type,
    thumbnail: [formData?.cover_media?.media_thumbnail],
    closedCoverMediaType: formData?.closed_cover_media?.type,
    closed_media_url: [formData?.closed_cover_media?.media_url],
    info_media_image: infoMedia,
    closed_info_media_image: closedInfoMedia,
    service_type: formData?.service_type,
    city: formData?.city,
    state: formData?.state,
    id: formData?.id,
    schedule_date: formData?.schedule_date
      ? moment(
          moment(formData?.schedule_date).format('YYYY-MM-DD HH:mm:ss'),
        ).utcOffset(0)
      : undefined,
    hindi_calendar_date: formData?.hindi_calendar_date,
    whatsapp_share_media: formData?.whatsapp_share_media?.media_url
      ? [formData?.whatsapp_share_media?.media_url]
      : null,
    whatsappMediaType: formData?.whatsapp_share_media?.type
      ? formData?.whatsapp_share_media?.type
      : null,
    language_code: languageState.monetisationLanguage,
    location_details: {
      temple_details: {
        ...formData?.location_details?.temple_details,
        image: [formData?.location_details?.temple_details?.image],
      },
      pandit_details: {
        ...formData?.location_details?.pandit_details,
        image: [formData?.location_details?.pandit_details?.image],
      },
      show_location_details: formData?.location_details?.show_location_details,
    },
    country_code: formData?.country_code,
    astro_pooja_list: formData?.astro_pooja_list?.length
      ? formData?.astro_pooja_list
      : [],
    tags: formData?.tags?.length ? formData?.tags : [],
    show_reorder_card_l2: formData?.show_reorder_card_l2 ? true : false,
    pooja_specificity: formData?.pooja_specificity,
    pooja_usecase: formData?.pooja_usecase,
    pooja_title: formData?.pooja_title,
    puja_faq: formData?.puja_faq,
    enable_puja_faq: formData?.puja_faq ? true : false,
    enable_mantra_data: formData?.mantra_data ? true : false,
    mantra_data: {
      ...formData?.mantra_data,
      icon: [formData?.mantra_data?.icon],
    },
    pickup_address: formData?.pickup_address,
    sku_code: formData?.sku_code,
  }
  if (formData?.closed_cover_media?.media_url) {
    formObject['closed_media_url'] = [formData?.closed_cover_media?.media_url]
  }
  if (video?.[0]?.media_url) {
    formObject['info_media_video'] = [video?.[0]?.media_url]
  }
  if (closedVideo?.[0]?.media_url) {
    formObject['closed_info_media_video'] = [closedVideo?.[0]?.media_url]
  }
  return formObject
}
export const parseCreateTempleApi = (formData: any) => {
  const formObject = {
    title: formData?.title,
    sub_title: formData?.sub_title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    hub_id: formData?.hub_id,
    icon_url: [formData?.icon_url],
    media_url: [formData?.cover_media?.media_url],
    coverMediaType: formData?.cover_media?.type,
    thumbnail: [formData?.cover_media?.thumbnail],
    cta: JSON.stringify(formData?.cta),
    item_image_url: [formData?.item_image_url],
    info_media_image: formData?.info_media_image
      ? [formData?.info_media_image]
      : [],
    info_media_video: formData?.info_media_video
      ? [formData?.info_media_video]
      : [],
    city: formData?.city,
    state: formData?.state,
  }
  return formObject
}

const parseAdditionalInfo = (additionInfo: string) => {
  const snippet = additionInfo
  const parser = new DOMParser()
  const doc = parser.parseFromString(snippet, 'text/html')
  const spans = doc.querySelectorAll('span.item span')
  const images = doc.querySelectorAll('img')

  const list = Array.from(spans).map(span => span.innerHTML.trim())
  const iconImg = [Array.from(images).map(img => img.getAttribute('src'))?.[0]]

  return list.length <= 0
    ? { error: true, additionInfo, iconImg }
    : { list, iconImg }
}

export const parseStoreProductList = (
  formData: ProductData,
  campaignList: any,
  storeList: any,
  languageState: LanguageState,
) => {
  let campaignArray: any[] = []
  campaignList?.map((campaign: any) =>
    campaignArray.push([campaign?.id, campaign.name]),
  )
  const stores = storeList?.map((store: any) => ({
    label: store?.title,
    value: store?.id,
  }))
  const productList: any = formData?.products
    ?.map((item: Product) => ({
      id: item?.id,
      title: item?.title,
      description: item?.description,
      image_url: [item?.image_url],
      bottomsheet_image_url: [item?.bottomsheet_image_url],
      price: item?.price,
      master_product_id: item?.master_product_id,
      position: item?.position || 0,
      active: item?.active,
      batch_size: item?.batch_size || 20,
      short_name: item?.short_name,
      quantities: item?.quantities || 0,
      additional_info:
        item?.additional_info_v2 && item?.bullet_icon_url
          ? { list: item?.additional_info_v2, iconImg: [item?.bullet_icon_url] }
          : parseAdditionalInfo(item?.additional_info || ''),
      member_size: item?.member_size ? item?.member_size : null,
      item_type: item?.item_type,
      language_code: item?.language_code,
      multi_add: item?.multi_add,
      specificity: item?.specificity,
      country_code: item?.country_code,
      currency: item?.currency,
      bestseller: item?.bestseller,
      deliverable: item?.deliverable,
      process_date: moment(
        moment(item?.process_date).format('YYYY-MM-DD HH:mm:ss'),
      ).utcOffset(0),
      campaignList: item?.campaigns
        ?.map((id: any) =>
          campaignList
            ?.filter((obj: any) => obj?.id === id)
            ?.map((obj: any) => obj?.name),
        )
        ?.flat(),
      campaignArray: campaignArray,
      storeList: stores,
      recommended: item?.recommended,
      exp_linked: item?.exp_linked,
      pickup_address: item?.pickup_address,
      sku_code: item?.sku_code,
      bottomsheet_medias: item?.bottomsheet_medias?.map(
        (item: { media_url: string; media_type: string }) => item?.media_url,
      ),
      is_igst: item?.is_igst,
      hsn_code: item?.hsn_code,
      tax_rate: item?.tax_rate,
    }))
    .sort((item_1: any, item_2: any) => item_1?.position - item_2?.position)
  return productList
}
export const formatAssignItemsApi = (formData: any): CreateInventory => {
  const inventoryList = formData.added.map((item: any, index: number) => ({
    master_product_id: item.id,
    name: item.title,
    image_url: item.image_url,
    bottomsheet_image_url: item.bottomsheet_image_url,
    description: item.description,
    price: item.price,
    quantities: 0,
    position: index,
    multi_add: item.multi_add || false,
    bestseller: false,
    cta: {
      type: 'whatsapp_share',
      color: 'green',
      text: 'Add to cart',
    },
    active: true,
    type: item.type,
    specificity: item.specificity,
  }))
  return { products: inventoryList }
}
export const formatFaqsAndreviewsApi = (
  formData: any,
  storeId: string,
  storeSession: StoreSessionDay[] | [],
): any => {
  //Current changes
  const formObject: any = {}
  formObject['faqs'] = {
    create: {
      faq_type: 'store',
      entity_id: storeId,
      faqs: formData?.faqs
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          question: item?.question,
          answer: item?.answer,
          position: item?.position,
          active: true,
        })),
    },
    update: {
      faq_type: 'store',
      entity_id: storeId,
      faqs: formData?.faqs
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          question: item?.question,
          answer: item?.answer,
          position: item?.position,
          active: true,
        })),
    },
  }
  formObject['reviews'] = {
    create: {
      store_id: storeId,
      reviews: formData?.reviews
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          review: item?.review,
          user_name: item?.user_name,
          user_image_url: item?.user_image_url?.[0],
          position: item?.position,
          rating: item?.rating,
        })),
    },
    update: {
      store_id: storeId,
      reviews: formData?.reviews
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          review: item?.review,
          user_name: item?.user_name,
          user_image_url: item?.user_image_url?.[0],
          position: item?.position,
          rating: item?.rating,
        })),
    },
  }
  formObject['pooja_benefits'] = {
    create: {
      page_type: 'pooja_benefits',
      entity_id: storeId,
      steps: formData?.pooja_benefits
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          step: item?.step,
          icon: item?.pooja_benefit_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
    update: {
      page_type: 'pooja_benefits',
      entity_id: storeId,
      steps: formData?.pooja_benefits
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          step: item?.step,
          icon: item?.pooja_benefit_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
  }
  formObject['pooja_process'] = {
    create: {
      page_type: 'pooja_process',
      entity_id: storeId,
      steps: formData?.pooja_process
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          step: item?.step,
          icon: item?.pooja_process_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
    update: {
      page_type: 'pooja_process',
      entity_id: storeId,
      steps: formData?.pooja_process
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          step: item?.step,
          icon: item?.pooja_process_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
  }

  const days = [0, 1, 2, 3, 4, 5, 6]
  const storeSessions = days?.map((day: any, index: number) => ({
    weekday: index,
    opening_hour: formData?.session?.[0]?.format('HH:mm'),
    closing_hour: formData?.session?.[1]?.format('HH:mm'),
    id: storeSession?.[index]?.id,
  }))

  return {
    faqs: { faqs: formObject['faqs'] },
    reviews: { reviews: formObject['reviews'] },
    pooja_benefits: {
      pooja_benefits: formObject['pooja_benefits'],
    },
    pooja_process: {
      pooja_process: formObject['pooja_process'],
    },
    status: formData.status,
    session: {
      sessions: storeSessions,
    },
  }
}
export const formatInventoryForEditAdd = (
  formData: any,
  countryCode: string,
  languageCode: string,
) => {
  return {
    master_product_id: formData?.id,
    title: formData?.title,
    image_url: formData?.image_url,
    bottomsheet_image_url: formData?.bottomsheet_image_url,
    description: formData?.description,
    price: formData?.price,
    archived: formData?.archived,
    item_type: formData?.item_type,
    multi_add: formData?.multi_add,
    bestseller: false,
    active: formData?.active || false,
    position: formData?.position || 0,
    quantities: formData?.quantities || 0,
    cta: {
      type: 'redirection',
      color: 'green',
      text: 'Add to cart',
    },
    is_igst: formData?.is_igst || false,
    hsn_code: formData?.hsn_code || null,
    tax_rate:
      formData?.tax_rate === '' || formData?.tax_rate == null
        ? null
        : Number(formData?.tax_rate),
    language_code: languageCode,
    currency: countryCode !== 'IN' ? 'USD' : 'INR',
    country_code: countryCode,
    bottomsheet_medias: formData?.bottomsheet_medias?.map((item: any) => ({
      media_type: 'image',
      media_url: item?.media_url,
    })),
  }
}

export const formatInventoryForEdit = (
  formData: any,
  languageCode: string,
  countryCode: string,
  campaignList?: any,
  serviceType?: string,
  storeType?: string,
) => {
  const selectedCampaignIds = formData?.campaignList
    ?.map((name: any) =>
      campaignList
        ?.filter((obj: any) => obj?.name === name)
        ?.map((obj: any) => obj?.id),
    )
    ?.flat()

  const divOpeningTag = `<div style="display: flex; flex-direction: column">`
  const divClosingTag = `</div>`
  const dynamicValues = formData.additional_info?.list
    ?.map(
      (info: string, index: number, array: string[]) =>
        `<span style="display: flex; flex-direction: row; align-items: start; width: 100%;" class="item"><img width="24" height="24" src=${
          formData?.additional_info?.iconImg?.[0]
        } /><span>${info}</span></span>${
          index !== array.length - 1 ? '<br />' : ''
        }`,
    )
    .join('')
  let result = divOpeningTag.concat(dynamicValues, divClosingTag)

  let payload: CreateInventoryPayload = {
    title: formData?.title,
    image_url: formData?.image_url?.[0],
    bottomsheet_image_url: formData?.bottomsheet_image_url?.[0],
    description: formData?.description,
    price: formData?.price,
    archived: formData?.archived,
    item_type: formData?.item_type,
    multi_add: formData?.multi_add,
    bestseller: formData?.bestseller || false,
    deliverable: formData?.deliverable,
    active: formData?.active,
    quantities: formData?.quantities || 0,
    position: formData?.position || 0,
    batch_size: formData?.batch_size,
    short_name: formData.short_name,
    language_code: languageCode,
    campaigns: selectedCampaignIds,
    country_code: countryCode,
    currency: formData?.currency,
    additional_info_v2: formData.additional_info?.list,
    bullet_icon_url: formData?.additional_info?.iconImg?.[0],
    ...(formData?.item_type === 'pooja' && {
      process_date: formatDateToIsoString(formData?.process_date) || '',
      member_size: formData?.member_size,
      additional_info: result,
      specificity: formData?.specificity,
      recommended: formData?.recommended,
    }),
    ...(formData?.pickup_address && {
      pickup_address: formData?.pickup_address,
    }),
    sku_code: formData?.sku_code,
    bottomsheet_medias: formData?.bottomsheet_medias?.map((item: string) => {
      const fileExtension = getMediaExtension(item)
      const mediaType = fileExtension ? getFileFormat(fileExtension) : 'image'
      return {
        media_type: mediaType,
        media_url: item,
      }
    }),
    is_igst: formData?.is_igst || false,
    hsn_code: formData?.hsn_code || null,
    tax_rate:
      formData?.tax_rate === '' || formData?.tax_rate == null
        ? null
        : Number(formData?.tax_rate),
  }
  if (serviceType === 'pooja' && formData?.item_type === 'item') {
    delete payload?.campaigns
  }

  return payload
}
