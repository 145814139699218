import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Collapse, Drawer, Form, Input, Row, Space, Tag, Typography, message } from 'antd'
import { useAppContext } from '@/components/AppContext';
import { getMonetisationPujaApi } from '@/utils/helper';
import { useForm } from 'antd/lib/form/Form'
import useFetch from '@/hooks/useFetch';
import { CustomLoader } from '@/components/Loader';
import EmptyState from '@/components/ui/EmptyState'
import { ShipmentDetails } from '../..';
import { AddressPayload } from '@a4b/api/src/modules/Monetisation/Puja/types/shipments';
import { openErrorNotification } from '@/utils';
import { CaretRightOutlined } from '@ant-design/icons';
import { OrderData, parseAddress } from '../../utils/helper';
const { Panel } = Collapse

interface Props {
    showDeliveryDataDrawer: boolean
    shipment: ShipmentDetails
    closeFormCallback: (refetch?: boolean) => void
}

const ClickPostDetails: React.FC<Props> = ({
    showDeliveryDataDrawer,
    shipment,
    closeFormCallback,
}) => {
    const [addressForm] = useForm()
    const { networkInstance, app, userProfile } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)

    const [isFormSubmitting, setIsFormSubmitting] = useState(false)

    const getOrderInfoByBookingId = useCallback(async () => {
        if (!shipment?.booking_id) {
            return { data: undefined }
        }
        try {
            const OrderRes = await monetisationPujaApi.orderApi.getOrderByBookingId(String(shipment?.booking_id))
            return {
                data: OrderRes?.data?.data?.order
            }
        } catch (error) {
            console.error("Error fetching address:", error)
            return {
                err: "Failed to fetch address details",
            }
        }
    }, [shipment?.booking_id, monetisationPujaApi.orderApi])

    const { data: order, error, loading } = useFetch(getOrderInfoByBookingId)

    useEffect(() => {
        if (order) {
            const parsedAddress = parseAddress(
                order as OrderData,
                userProfile?.email as string,
                shipment?.shipment_user_data?.username as string
            )
            addressForm.setFieldsValue(parsedAddress)
        } else {
            addressForm.resetFields()
        }
    }, [addressForm, order, shipment?.shipment_user_data?.username, userProfile?.email])

    const handleSubmit = async (values: AddressPayload) => {
        if (!shipment?.id) {
            message.warning('ShipmentId not found')
            return
        }
        setIsFormSubmitting(true)
        try {
            message.loading('Form submitting..', 1)
            await monetisationPujaApi.shipmentApi.createClickPostGenerate(shipment?.id, values)
            closeFormCallback(true)
            message.success('Form submitted!', 2)
        } catch (error) {
            console.error("Error submitting address form:", error)
            openErrorNotification(error)
        } finally {
            setIsFormSubmitting(false)
        }
    }

    const handleReTriggerBtn = () => {
        const formValues = addressForm.getFieldsValue()
        handleSubmit({ ...formValues, address: null })
    }

    return (
        <Drawer
            title={`Delivery Data - Address`}
            placement='right'
            onClose={() => closeFormCallback()}
            visible={showDeliveryDataDrawer}
            destroyOnClose={true}
            size="large"
        >
            {
                shipment?.delivery_data &&
                <Collapse
                    bordered={false}
                    defaultActiveKey={'1'}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="site-collapse-custom-collapse"
                >
                    <Panel header="Delivery data" key="1" className="site-collapse-custom-panel">
                        <Row>
                            <Col span={8}>
                                <Typography.Title level={5}>Success</Typography.Title>
                            </Col>
                            <Col span={16}><Tag color={shipment?.delivery_data?.meta?.success ? 'green' : 'red'}>{shipment?.delivery_data?.meta?.success ? 'true' : 'false'}</Tag></Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Typography.Title level={5}>Message</Typography.Title>
                            </Col>
                            <Col span={16}>{shipment?.delivery_data?.meta?.message}</Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Typography.Title level={5}>Reference Number:</Typography.Title>
                            </Col>
                            <Col span={16}>{shipment?.delivery_data?.meta?.reference_number}</Col>
                        </Row>
                    </Panel>
                </Collapse>
            }
            {loading ? (
                <Row justify='center' style={{ marginTop: '20px' }}>
                    <CustomLoader />
                </Row>
            ) : error ? (
                <EmptyState errorText='No Address found!' />
            ) : (
                <div style={{ marginTop: shipment?.delivery_data ? '30px' : '0px' }}>
                    <Form
                        form={addressForm}
                        layout='vertical'
                        onFinish={handleSubmit}
                    >
                        <Typography.Title level={5}>Order Info</Typography.Title>
                        <Col span={12}>
                            <Form.Item
                                label='Order ID'
                                name='order_id'
                                rules={[{ required: false }]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Typography.Title level={5} style={{ marginTop: '10px' }}>Address</Typography.Title>
                        <Row gutter={16}>
                            <Col span={12}
                            >
                                <Form.Item
                                    label='City'
                                    name={['address', 'city_name']}
                                    rules={[{ required: true, message: 'Please enter city' }]}
                                >
                                    <Input placeholder='Enter city' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='State'
                                    name={['address', 'state_name']}
                                    rules={[{ required: true, message: 'Please enter state' }]}
                                >
                                    <Input placeholder='Enter state' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='House Info'
                                    name={['address', 'house_info']}
                                    rules={[{ required: true, message: 'Please enter House Info' }]}
                                >
                                    <Input placeholder='Enter House Info' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Area Info'
                                    name={['address', 'area_info']}
                                    rules={[{ required: true, message: 'Please enter Area Info' }]}
                                >
                                    <Input placeholder='Enter Area Info' />
                                </Form.Item>
                            </Col>


                            <Col span={12}>
                                <Form.Item
                                    label='Pincode'
                                    name={['address', 'pin_code']}
                                    rules={[
                                        { required: true, message: 'Please enter pincode' },
                                        { pattern: /^\d{6}$/, message: 'Please enter a valid 6-digit pincode' }
                                    ]}
                                >
                                    <Input placeholder='Enter pincode' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Landmark'
                                    name={['address', 'landmark']}
                                    rules={[{ required: true, message: 'Please enter landmark' }]}
                                >
                                    <Input placeholder='Enter nearby landmark' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Typography.Title level={5}>User details</Typography.Title>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Username'
                                    name='username'
                                    rules={[{ required: true, message: 'Please enter username' }]}
                                >
                                    <Input placeholder='Enter username' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label='Admin Email'
                                    name='admin_user_email'
                                    rules={[{ required: false }]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row>
                            <Col span={24} style={{ marginTop: 24 }}>
                                <Space>
                                    <Button type='primary' htmlType="submit" loading={isFormSubmitting} disabled={isFormSubmitting}>
                                        Update Address
                                    </Button>
                                    <Button
                                        onClick={handleReTriggerBtn}
                                        disabled={isFormSubmitting}
                                        type='ghost'
                                    >
                                        Re-trigger
                                    </Button>
                                    <Button onClick={() => closeFormCallback()} disabled={isFormSubmitting}>
                                        Cancel
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Drawer>
    )
}

export default ClickPostDetails