import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { OrderData, PoojaOrder } from './types'

class OrdersApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getOrders = (
    pageNo: string,
    orderStatus: string,
    serviceType: string,
    bookingId: string,
    contactNumber: string,
    userId: string,
    storeId: string,
    bookingWhatsapp: string,
    bookingMemberData: string,
  ) =>
    this.api.get<PoojaOrder>(`/gw1/puja/internal/v1/orders`, {
      params: {
        page_no: pageNo,
        order_status: orderStatus,
        ...(serviceType && { service_type: serviceType }),
        ...(bookingId && { booking_id: bookingId }),
        ...(contactNumber && { contact_number: contactNumber }),
        ...(userId && { user_id: userId }),
        ...(storeId && { store_id: storeId }),
        ...(bookingWhatsapp && { booking_whatsapp_number: bookingWhatsapp }),
        ...(bookingMemberData && { booking_member_data: bookingMemberData }),
      },
    })

  getOrderByBookingId = (bookingId: string) =>
    this.api.get<OrderData>(
      `/gw2/puja/internal/v1/orders/booking-id/${bookingId}`,
      {},
    )

  updateOrder = (orderId: string, action: string, payload: any) =>
    this.api.patch(
      `/gw1/puja/internal/v1/orders/${orderId}/${action}`,
      payload,
      {
        headers: { 'x-afb-platform': 'web' },
      },
    )
}

export default OrdersApi
