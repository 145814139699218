import { CustomLoader } from "@/components/Loader";
import { CreateStore } from "@a4b/api/src/modules/Monetisation/Puja/types";
import { Button, Input, Row, Spin, TableProps, Tooltip } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

const useTableConfig = (
    storeList: CreateStore[],
    isLoading: boolean,
    setShowPujaUpdateDrawer: React.Dispatch<React.SetStateAction<boolean>>,
    setShowClickPostConfirmationPopup: React.Dispatch<React.SetStateAction<boolean>>,
    setStore: React.Dispatch<React.SetStateAction<{ id: string, title: string }>>,
): TableProps<CreateStore> => {
    const [pagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 1000,
    })
    const [dataSource, setDataSource] = useState(storeList)
    const [title, setTitle] = useState('')
    const [id, setId] = useState('')

    useEffect(() => { setDataSource(storeList) }, [storeList])

    const filterSearchTitleInput = useCallback(() => {
        return <Input
            placeholder="Search Title"
            value={title}
            onChange={e => {
                const currValue = e.target.value;
                setTitle(currValue);
                const filteredData: any = storeList.filter(entry =>
                    entry.title.toLowerCase().includes(currValue.toLowerCase())
                )
                setDataSource(filteredData)
            }}
            allowClear
        />
    }, [storeList, title])

    const filterSearchIdInput = useCallback(() => {
        return <Input
            placeholder="Search ID"
            value={id}
            onChange={e => {
                const currValue = e.target.value
                setId(currValue)
                const filteredData: any = storeList.filter(entry =>
                    entry.id.toLowerCase().includes(currValue.toLowerCase())
                )
                setDataSource(filteredData)
            }}
            allowClear
        />
    }, [id, storeList])

    const triggerEdit = useCallback((record: any) => {
        setStore({
            id: record.id,
            title: record.title,
        })
        setShowPujaUpdateDrawer(true)
    }, [setShowPujaUpdateDrawer, setStore])

    const triggerCreateClickPost = useCallback((record: any) => {
        setStore({
            id: record.id,
            title: record.title,
        })
        setShowClickPostConfirmationPopup(true)
    }, [setShowClickPostConfirmationPopup, setStore])

    const dataColumns = useMemo(() => [
        {
            title: <div style={{ display: 'flex', flexDirection: 'column' }}> <span>Title</span> <div>{filterSearchTitleInput()}</div></div>,
            dataIndex: 'title',
            key: 'no_of_items',
            width: '30%',
        },
        {
            title: <div style={{ display: 'flex', flexDirection: 'column' }}> <span>Store Id</span> <div>{filterSearchIdInput()}</div></div>,
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ellipsis: true,
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: '',
            key: 'action',
            width: '35%',
            render: (text: any, record: any, index: any) => {
                return (
                    <Row style={{ marginLeft: '100px' }} justify="space-between">
                        <Button type='primary' onClick={() => triggerEdit(record)}>
                            Puja updates
                        </Button>
                        <Button type='link'>
                            <Link to={`/monetisation/puja/hub/${record?.hub_id}/temple/${record?.id}/batch?name=${record?.title}&language_code=hi&alt_name=Hindi&country_code=IN`}>
                                Create Batch
                            </Link>
                        </Button>
                        <Button onClick={() => triggerCreateClickPost(record)}>Clickpost create</Button>
                    </Row>
                )
            },
        },
    ], [filterSearchIdInput, filterSearchTitleInput, triggerCreateClickPost, triggerEdit])

    return {
        pagination, dataSource: dataSource, columns: dataColumns, loading: {
            spinning: isLoading,
            indicator: <Spin indicator={<CustomLoader />} />,
        }, size: 'middle', sticky: true
    }
}

export default useTableConfig