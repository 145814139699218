import { LoginState } from '@/entities/UserProfile'
import ContentUploadScreen from '@/modules/content/screens/ContentUpload'
import CreatorListingScreen from '@/modules/content/screens/CreatorListing'
import ModeratorListingScreen from '@/modules/content/screens/ModeratorListing'
import Rashifal from '@/modules/content/screens/Rashifal'
import TagContentScheduleCreateEdit from '@/modules/content/screens/TagContentScheduleCreateEdit'
import TagContentScheduleList from '@/modules/content/screens/TagContentScheduleList'
import TagScheduleCreateEdit from '@/modules/content/screens/TagScheduleCreateEdit'
import TagScheduleList from '@/modules/content/screens/TagScheduleList'
import TagsCreateEditScreen from '@/modules/content/screens/TagsCreateEdit'
import TagsListingScreen from '@/modules/content/screens/TagsListing'
import MusicListing from '@/modules/music/screens/MusicListing'
import MusicPlaylistCreateEdit from '@/modules/music/screens/MusicPlaylistCreateEdit'
import MonetisationRoutes from '@/modules/monetisation/routes'
import LoginPage from '@/ui/organisms/Login'
import CreateSchedule from '@feeds/container/schedules/CreateSchedule'
import ManageSchedule from '@feeds/container/schedules/ManageSchedule'
import ManageWidgets from '@feeds/container/widgets/ManageWidgets'
import CDNUploadScreen from '@misc/screens/CDNUpload'
import MusicUploadScreen from '@misc/screens/MusicUpload'
import {
  BrowserRouter as Router, Navigate,
  Route, Routes
} from 'react-router-dom'
import NewScreenDraft from '@/modules/content/screens/NewScreenDraft'
import EpujaRoutes from '@/modules/epuja/routes'
import MusicBulkUpdate from '@/modules/music/screens/MusicBulkUpdate'
import MusicCreateEdit from '@/modules/music/screens/MusicCreateEdit'
import MusicPlaylistListing from '@/modules/music/screens/MusicPlaylistListing'
import SocialAdmins from '@/modules/social.1.5/screens/admins'
import Communities from '@/modules/social.1.5/screens/communities'
import SocialCommunityTags from '@/modules/social.1.5/screens/community-tag'
import SocialContentTags from '@/modules/social.1.5/screens/content-tag'
import SocialMessages from '@/modules/social.1.5/screens/messages'
import TempleGodItems from '@/modules/shrine/screens/god-items'
import VideoTemplate from '@/modules/music/screens/VideoTemplate'
import TempleGods from '@/modules/shrine/screens/gods'
import ManageStories from '@/modules/shrine/screens/stories'
import AvatarScreen from '@/modules/shrine/screens/avatars'
import ShrineItemsSchedules from '@/modules/shrine/screens/god-items/schedule';
import ManageActionCards from '@/modules/shrine/screens/action-cards'
import Languages from '@/modules/translations/screens/addTranslations/addTranslations'
import Services from '@/modules/translations/screens/services/services'
import { useAppContext } from '../AppContext'
import ConditionalRoute from './ConditionalRoute'
import PrivateRoute from './PrivateRoute'
import ShareTextApps from '../../modules/ShareText/screens/ShareTextApps'
import ShareTextFunctionality from '../../modules/ShareText/screens/ShareTextFunctionality'
import ShareTextListing from '../../modules/ShareText/screens/ShareTextListing'
import SuggestionCards from '../../modules/music/screens/SuggestionCards'
import Thumbnails from '../../modules/music/screens/Thumbnails'
import Journey from '../../modules/in-app-nudges/screens/Journey'
import DynamicCohort from '../../modules/in-app-nudges/screens/DynamicCohort'
import PlaylistBulkUpdate from '../../modules/music/screens/PlaylistBulkUpdate'
import Events from '../../modules/events/screens/Events'
import TempleList from '@/modules/temples/container/templeList'
import ManageTempleCreate from '@/modules/temples/container/ManageTempleCreate'
import ManageCities from '@/modules/temples/container/ManageCities'
import ManageCityCreate from '@/modules/temples/container/ManageCityCreate'
import Nudges from '../../modules/in-app-nudges/screens/Nudges'
import GitaChapters from '../../modules/gita/screens/Chapters'
import GitaShlokas from '../../modules/gita/screens/Shlokas'
import Quiz from '../../modules/coins/screens/quiz'
import AbExperimentsManager from '../../modules/ab-experiments/screens/manage'
import RoleManagement from '../../modules/admin-controls/screens/roles'
import { useCallback } from 'react'
import { LoginPayload } from '@a4b/api/src/modules/AdminControl/types'
import Cookies from 'js-cookie'
import { USER_ROLES } from '../../utils/constants'

//Puja
import CreateTemplePuja from '@/modules/monetisation/puja/ManageStores/CreateStore'
import HubListPuja from '@/modules/monetisation/puja/HubList'
import ManageItemPuja from '@/modules/monetisation/puja/ManageItem'
import ManagePagesPuja from '@/modules/monetisation/puja/ManagePageSections/screens/ManagePages'
import ManagePageSectionsPuja from '@/modules/monetisation/puja/ManagePageSections'
import ManageTemplesPuja from '@/modules/monetisation/puja/ManageStores/StoreList'
import PoojaOrdersPuja from '@/modules/monetisation/puja/ManageOrders'
import TempleListPuja from '@/modules/monetisation/puja/TempleList'
import ManageUserSegmentsPuja from '@/modules/monetisation/puja/ManageUserSegments'
import ManageS3UploadPuja from '@/modules/monetisation/puja/ManageS3Upload'
import ManagePujaUpdates from '@/modules/monetisation/puja/ManagePujaUpdates'
import Filters from '@/modules/monetisation/puja/Filters'

//Astro
import AstrologerList from '@/modules/monetisation/Astrologer/ManageAstrologer/AstrologerList'
import ManageAstrologer from '@/modules/monetisation/Astrologer/ManageAstrologer/index'
import AstrologerPageSection from '@/modules/monetisation/Astrologer/AstrologerPageSection'
import ManageCalls from '@/modules/monetisation/Astrologer/ManageCalls'

//wallet
import RechargePackages from '@/modules/wallet/screens/RechargePackages/';
import ManageBatches from '@/modules/monetisation/puja/ManageBatches'
import Schedule from '@/modules/shrine/screens/schedule'
import ManageAddresses from '@/modules/monetisation/puja/ManageAddresses'

const AppRoutes = (props: any) => {

  const { userProfile, networkInstance, userRoles, monetisationRoles } = useAppContext()
  const loggedIn =
    userProfile.loginStatus === LoginState.LOGGED_IN

  const login = useCallback(async (loginPayload: LoginPayload) => {
    const res = await networkInstance.clientWithHeaders.adminControl.login(loginPayload)
    Cookies.set('gauth-token', `${loginPayload.id_token}`, { expires: 1 })
    localStorage.setItem("userinfo", JSON.stringify(res.data.data.token))
    window.location.href = '/'
  }, [networkInstance.clientWithHeaders.adminControl])

  return (
    <Router>
      <Routes>
        <Route
          path='/*'
          element={<PrivateRoute />}
        />
        <Route
          path='/login'
          element={!loggedIn ? <LoginPage login={login} /> : <Navigate to='/' />}
        />
        <Route
          path='/new-draft'
          element={<NewScreenDraft></NewScreenDraft>}
        />
        <Route element={<PrivateRoute />}>
          <Route path='feeds'>
            <Route index element={<ManageWidgets />} />
            <Route path='schedule/create' element={<CreateSchedule />} />
            <Route path='schedule/edit/:id' element={<CreateSchedule />} />
            <Route path='schedule' element={<ManageSchedule />} />
            <Route path='widgets' element={<ManageWidgets />} />
          </Route>
          <Route path='misc' >
            <Route path='cdnupload' element={<CDNUploadScreen />} />
            <Route path='music-upload' element={<MusicUploadScreen />} />
          </Route>
          <Route path='content'>
            <Route index element={<ContentUploadScreen />} />
            <Route path='create-draft' element={<ContentUploadScreen />} />
            <Route
              path='edit-draft/:libraryId'
              element={<ContentUploadScreen />}
            />
            <Route path='creator-listing' element={<CreatorListingScreen />} />
            <Route
              path='moderator-listing'
              element={<ModeratorListingScreen />}
            />
            <Route path='tags/create' element={<TagsCreateEditScreen />} />
            <Route path='tags/edit/:tagId' element={<TagsCreateEditScreen />} />
            <Route path='tags' element={<TagsListingScreen />} />
            <Route
              path='tag-schedule/create'
              element={<TagScheduleCreateEdit />}
            />
            <Route
              path='tag-schedule/edit/:scheduleId'
              element={<TagScheduleCreateEdit />}
            />
            <Route path='tag-schedule' element={<TagScheduleList />} />
            <Route
              path='tag-content-schedule/:tagId/create'
              element={<TagContentScheduleCreateEdit />}
            />
            <Route
              path='tag-content-schedule/:tagId/edit/:scheduleId'
              element={<TagContentScheduleCreateEdit />}
            />
            <Route
              path='tag-content-schedule/:tagId'
              element={<TagContentScheduleList />}
            />
            <Route
              path='rashifal'
              element={<Rashifal />}
            />
          </Route>
          <Route path='music'>
            <Route path='listing' element={<MusicListing />} />
            <Route path='bulk-update' element={<MusicBulkUpdate />}></Route>
            <Route path='playlists' element={<MusicPlaylistListing />} />
            <Route path='playlists/create' element={<MusicPlaylistCreateEdit />} />
            <Route path='bulk-update-playlist' element={<PlaylistBulkUpdate />}></Route>
            <Route path='playlists/edit/:playlistId' element={<MusicPlaylistCreateEdit />} />
            <Route path='edit/:audioId' element={<MusicCreateEdit />} />
            <Route path='video-template' element={<VideoTemplate />} />
            <Route path='suggestion-cards' element={<SuggestionCards />} />
            <Route path='thumbnails' element={<Thumbnails />} />
          </Route>
          <Route path='social.1.5'>
            <Route path='community/tag' element={<SocialCommunityTags />} />
            <Route path='content/tag' element={<SocialContentTags />} />
            <Route path='communities' element={<Communities />} />
            <Route path='messages' element={<SocialMessages />} />
            <Route path='messages/:id' element={<SocialMessages />} />
            <Route path='admins' element={<SocialAdmins />} />
          </Route>
          <Route path='shrine'>
            <Route path='gods' element={<TempleGods />} />
            <Route path='god-items' element={<TempleGodItems />} />
            <Route path='stories' element={<ManageStories />} />
            <Route path='gods/:god_id/avatars/' element={<AvatarScreen />} />
            <Route path='gods/:god_id/avatars/:avatar_id' element={<AvatarScreen />} />
            <Route path='action-cards' element={<ManageActionCards />} />
            <Route path='schedule' element={<Schedule />} />
          </Route>
          <Route path='translations'>
            <Route path='services' element={<Services />} />
          </Route>
          <Route path='share-text'>
            <Route path='app' element={<ShareTextApps />} />
            <Route path='functionality' element={<ShareTextFunctionality />} />
            <Route path='listing' element={<ShareTextListing />} />
          </Route>
          <Route path='in-app-nudges'>
            <Route path='journey' element={<Journey />} />
            <Route path='nudges' element={<Nudges />} />
            <Route path='dynamic-cohort' element={<DynamicCohort />} />
          </Route>
          <Route path='events-management'>
            <Route path='list' element={<Events />} />
            <Route path='list/:id' element={<Events />} />
          </Route>
          <Route path='temples'>
            <Route path='manage-temples' element={<TempleList />} />
            <Route path='create' element={<ManageTempleCreate />} />
            <Route path='manage-cities' element={<ManageCities />} />
            <Route path='city' element={<ManageCityCreate />} />
          </Route>
          <Route path='events-management'>
            <Route path='list' element={<Events />} />
            <Route path='list/:id' element={<Events />} />
          </Route>
          <Route path='ab-experiments'>
            <Route path="manage" element={<AbExperimentsManager />}></Route>
          </Route>
          <Route path='temples'>
            <Route path='manage-temples' element={<TempleList />} />
            <Route path='create' element={<ManageTempleCreate />} />
            <Route path='manage-cities' element={<ManageCities />} />
            <Route path='city' element={<ManageCityCreate />} />
          </Route>
          <Route path='languages/:name' element={<Languages />} />
          <Route index element={<ConditionalRoute />} />
          <Route path="gita">
            <Route path="chapters" element={<GitaChapters />}></Route>
            <Route path="chapters/:id/shlokas" element={<GitaShlokas />}></Route>
          </Route>
          <Route path="coins">
            <Route path="quiz" element={<Quiz />}></Route>
          </Route>
          {
            userRoles.includes(USER_ROLES.ADMIN_SUPER) &&
            <Route path="admin">
              <Route path="roles" element={<RoleManagement />}></Route>
            </Route>
          }
        </Route>
      </Routes>
      {
        (monetisationRoles?.roles?.user_type === 'hub_manager' ||
          monetisationRoles?.roles?.user_type === 'admin' ||
          monetisationRoles?.roles?.user_type === 'super_admin'
        ) &&
        <>
          <MonetisationRoutes />
          <Routes>
            <Route path='astro' element={<PrivateRoute />}>
              <Route path='puja' element={<PrivateRoute />}>
                <Route index element={<HubListPuja />} />
                <Route path='hub/:hubId' element={<TempleListPuja />} />
                <Route
                  path='hub/:hubId/temple/:templeId/batch'
                  element={<ManageBatches />}
                />
                <Route path='temples' element={<ManageTemplesPuja />} />
                <Route path='filters' element={<Filters />} />
                <Route path='puja-updates' element={<ManagePujaUpdates />} />
                <Route path='items' element={<ManageItemPuja />} />
                <Route path='manage_page_section' element={<ManagePageSectionsPuja />} />
                <Route path='pages' element={<ManagePagesPuja />} />
                <Route path='temple/create' element={<CreateTemplePuja />} />

                <Route element={<HubListPuja />} />
                <Route path='s3-upload' element={<ManageS3UploadPuja />} />

                <Route path='epuja' element={<PrivateRoute />}>
                  <Route path='video-upload' element={<TempleListPuja />} />
                </Route>
                <Route path='pooja-orders' element={<PrivateRoute />}>
                  <Route path='orders' element={<PoojaOrdersPuja />} />
                </Route>
                <Route path='segments' element={<PrivateRoute />}>
                  <Route path='manage-user-segments' element={<ManageUserSegmentsPuja />} />
                </Route>
                <Route path='manage-addresses' element={<ManageAddresses />} />
              </Route>
              <Route path='astrologer'>
                <Route path='astrologer-list' element={<AstrologerList />} />
                <Route path='create' element={<ManageAstrologer />} />
                <Route path='manage-page-section' element={<AstrologerPageSection />} />
                <Route path='manage-calls' element={<ManageCalls />} />
              </Route>
              <Route path='wallet'>
                <Route path='recharge-packages-list' element={<RechargePackages />} />
              </Route>
            </Route>
          </Routes>
        </>
      }
      <EpujaRoutes />
    </Router >
  )
}

export default AppRoutes
