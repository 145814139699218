import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Dropdown, Button, Space, Menu } from "antd";
import { Layout } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';
import TopHeader from '@/ui/organisms/TopHeader'
import hubManagerMenus from '@/utils/menus/hubManagerMenus'
import menuRenderConfig from '@/utils/menus/menuRenderConfig'
import styled from 'styled-components'
import { SUPPORTED_LANGUAGES_LIST, SUPPORTED_COUNTRY_LIST, USER_ROLES, A4B_APPS } from "@/utils/constants";
import { useAppContext } from "@/components/AppContext";
import adminMenus from '@/utils/menus/adminMenus'
import astrologerMenu from '@/utils/menus/astrologerMenu'

interface Props {
  loggedIn: boolean
  userRole: string
  roles: USER_ROLES[]
}

const { Content } = Layout

const AdminContent = styled(Content)`
  margin-top: 64px;
  overflow: scroll;
  height: calc(100vh - 64px);
  `

const LanguageDropdown = ({ isActive, menu, selectedName }: any) => (
  <Dropdown overlay={menu} disabled={!isActive} overlayStyle={{ maxHeight: '400px', overflowY: 'auto', border: 'none', width: '150px' }}>
    <Space>
      <Button className="languagesdropdown" disabled={!isActive}>
        {selectedName} <CaretDownOutlined />
      </Button>
    </Space>
  </Dropdown>
)

const DefaultLayout: React.FC<Props> = ({
  children,
  loggedIn,
  userRole,
  roles,
}) => {
  const [selectedKey, setSelectedKey] = useState('')
  const [languagesList, setLanguagesList] = useState<any>()
  const [searchParams, setSearchParams] = useSearchParams()
  const { networkInstance, languageState, setLanguageContext, countryState, setCountryState, setApp } = useAppContext();
  const [isContextUpdated, setIsContextUpdated] = useState(false)
  const routes = ['/monetisation/', '/astrologer/', '/astro/']

  let location = useLocation()
  const validKeys = new Set(["offerings", "astrologer", "campaigns", "puja", "wallet", "astro"]) // astro to be added

  useEffect(() => {
    if (location.pathname.startsWith('/astro')) {
      setApp(A4B_APPS.ASTRO)
    } else {
      setApp(A4B_APPS.SRIMANDIR)
    }
    setIsContextUpdated(true);
  }, [])

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'monetisation') {
      setSelectedKey(location.pathname.split('/')[2]) // to indicate active tab puja & offerings (Both are starting with /monetisation)
    } else {
      setSelectedKey(location.pathname.split('/')[1])
    }

    console.log(location.pathname)

    // temporary check for new astro
    // if (location.pathname.startsWith('/astro/puja')) {
    //   settingQueryParams("language_code", 'en-US')
    //   settingQueryParams("alt_name", getLanguageName('en-US') || "English")
    //   settingQueryParams('country_code', 'US')

    //   setSearchParams(searchParams)
    //   setLanguageContext({ ...languageState, isLanguageSelectionActive: false, monetisationLanguage: 'en-US' })
    //   setCountryState({ ...countryState, isCountryCodeActive: false, countryCode: 'US' })
    // } else if (location.pathname.split('/')[1] === 'astro') {
    //   settingQueryParams("language_code", 'en')
    //   settingQueryParams("alt_name", getLanguageName('en') || "English")
    //   settingQueryParams('country_code', 'US')

    //   setSearchParams(searchParams)
    //   setLanguageContext({ ...languageState, isLanguageSelectionActive: false, monetisationLanguage: 'en' })
    //   setCountryState({ ...countryState, isCountryCodeActive: false, countryCode: 'US' })
    //   return
    // }

    if (!routes.some(prefix => location.pathname.startsWith(prefix))) {
      return
    }

    let language_state: boolean = true
    let country_state: boolean = true

    if (location.pathname.includes('/astrologer/create')) {
      language_state = false
      country_state = false
    }
    if (location.pathname.includes('/monetisation/offerings/temples') || location.pathname.includes('/monetisation/offerings/temple/create')) {
      if (searchParams.get("edit") === 'true') {
        language_state = false
        country_state = false
      } else {
        language_state = true
        country_state = true
      }
    }
    fetchLanguages()
    const language = searchParams.get("language_code") || languageState.monetisationLanguage || "hi"
    const country = searchParams.get("country_code") || countryState.countryCode || 'IN'

    settingQueryParams("language_code", language)
    settingQueryParams("alt_name", getLanguageName(language) || "Hindi")
    settingQueryParams('country_code', country)

    setSearchParams(searchParams)
    setLanguageContext({ ...languageState, isLanguageSelectionActive: language_state, monetisationLanguage: language })
    setCountryState({ ...countryState, isCountryCodeActive: country_state, countryCode: country })
  }, [])

  const getLanguageName = (key: string) =>
    SUPPORTED_LANGUAGES_LIST.find((lang: any) =>
      lang?.value === key
    )?.alt_name

  const getCountryName = (code: string) =>
    SUPPORTED_COUNTRY_LIST.find((country: any) =>
      country?.country_code === code
    )?.country_name


  const settingQueryParams = useCallback((name: string, value: string) => searchParams.set(name, value), [searchParams])

  const fetchLanguages = async () => {
    try {
      const responseLanguages = await networkInstance.clientWithHeaders.languagesApi.getLanguages()
      setLanguagesList(responseLanguages?.data?.data)
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleClick = useCallback(({ key }: { key: string }) => {
    const alt_name: any = getLanguageName(key)
    settingQueryParams("language_code", key)
    settingQueryParams("alt_name", alt_name)
    setSearchParams(searchParams);
    setLanguageContext({ ...languageState, monetisationLanguage: key });
  }, [languageState, searchParams, setLanguageContext, setSearchParams, settingQueryParams])

  const handleCountryCode = useCallback(({ key }: { key: string }) => {
    settingQueryParams('country_code', key)
    setSearchParams(searchParams);
    setCountryState({ ...countryState, countryCode: key });
  }, [countryState, searchParams, setCountryState, setSearchParams, settingQueryParams])

  const getMenu = useCallback(() => {
    if (userRole === 'admin' || userRole === 'super_admin') {
      if (roles.includes(USER_ROLES.ADMIN_SUPER)) {
        return [...adminMenus, ...menuRenderConfig, {
          title: "Admin Control",
          id: "Admin",
          linkItem: [
            {
              name: "Role management",
              path: "/admin/roles",
              id: "/admin/roles"
            }
          ]
        }]
      } else {
        return [...adminMenus, ...menuRenderConfig]
      }
    } else if (userRole === 'hub_manager') {
      return hubManagerMenus
    } else if (userRole === 'astrologer') {
      return astrologerMenu
    } else {
      if (roles.includes(USER_ROLES.ADMIN_SUPER)) {
        return [...menuRenderConfig, {
          title: "Admin Control",
          id: "Admin",
          linkItem: [
            {
              name: "Role management",
              path: "/admin/roles",
              id: "/admin/roles"
            }
          ]
        }]
      } else {
        return menuRenderConfig
      }
    }
  }, [roles, userRole])

  const menu = useMemo(() => (
    <Menu onClick={handleClick}>
      {languagesList && Object.keys(languagesList).map((item: string) =>
        <Menu.Item key={item}>{getLanguageName(item)}</Menu.Item>
      )}
    </Menu>
  ), [handleClick, languagesList])

  const countryMenu = useMemo(() => (
    <Menu onClick={handleCountryCode}>
      {SUPPORTED_COUNTRY_LIST.map((item) =>
        <Menu.Item key={item?.country_code}>{item?.country_name}</Menu.Item>
      )}
    </Menu>
  ), [handleCountryCode])

  const isValidKey = validKeys.has(selectedKey);
  const countryName = getCountryName(countryState?.countryCode);
  const languageName = getLanguageName(languageState.monetisationLanguage);

  return (
    <Layout>
      <TopHeader
        loggedIn={loggedIn}
        menu={getMenu()}
        selectedKey={selectedKey}
      />
      <AdminContent>
        {isValidKey && (
          <div id="language_dropdown" style={{
            float: 'right',
            margin: '0px 43px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right'
          }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LanguageDropdown
                isActive={countryState?.isCountryCodeActive}
                menu={countryMenu}
                selectedName={countryName}
              />
              <div style={{ marginLeft: '10px' }}>
                <LanguageDropdown
                  isActive={languageState.isLanguageSelectionActive}
                  menu={menu}
                  selectedName={languageName}
                />
              </div>
            </div>
          </div>
        )}
        {isContextUpdated && children}
      </AdminContent>
    </Layout >

  )
}

export default DefaultLayout