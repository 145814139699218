import { useState, useCallback } from 'react'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { Button, Card, Row, Table } from 'antd';
import useTableConfig from './hooks/useTableConfig'
import { AddressData } from '@a4b/api/src/modules/Monetisation/Puja/types/address'
import useFetch from '@/hooks/useFetch'
import EmptyState from '@/components/ui/EmptyState';
import { getMonetisationPujaApi } from '@/utils/helper';
import AddressForm from './components/AddressForm';
import styled from 'styled-components';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`;

const ManageAddresses = () => {
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)
    const [addressFormDrawer, setAddressFormDrawer] = useState<{ show: boolean, id: string | undefined }>({ show: false, id: undefined })

    const getAddressList = useCallback(async () => {
        try {
            const addressListRes = await monetisationPujaApi.addressesApi.getAllAddress()
            return {
                data: addressListRes?.data?.data
            }
        } catch (error) {
            console.log("Error: ", error)
            return {
                err: "Failed to fetch adresses",
            }
        }
    }, [monetisationPujaApi.addressesApi])

    const { data, error, loading, refetch: refetchAddressList } = useFetch(getAddressList)

    const triggerEdit = (record: any) => {
        setAddressFormDrawer({
            show: true,
            id: record.id
        })
    }

    const tableConfig = useTableConfig(data as AddressData, loading, triggerEdit)

    const handleCreateAddress = () => {
        setAddressFormDrawer({
            show: true,
            id: undefined
        })
    }

    const handleCloseAddressForm = useCallback((refetch: boolean = false) => {
        setAddressFormDrawer({
            show: false,
            id: undefined
        })

        if (refetch) {
            refetchAddressList()
        }
    }, [refetchAddressList])


    return (
        <div style={{ padding: '60px 40px' }}>
            {
                error ? <EmptyState errorText='No addresses found!' /> :
                    <Container
                        title='Manage Addresses'
                        style={{ width: '100%' }}
                        extra={
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Button type='default' onClick={handleCreateAddress}>Create address</Button>
                            </Row>
                        }
                    >
                        <Table {...tableConfig} />
                    </Container>
            }
            {addressFormDrawer?.show &&
                <AddressForm addressFormDrawer={addressFormDrawer} isEditJourney={!!addressFormDrawer.id} closeFormCallback={handleCloseAddressForm} />
            }
        </div>
    )
}

export default withDefaultLayout(ManageAddresses)
