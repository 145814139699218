import { useAppContext } from "@/components/AppContext";
import useFetch from "@/hooks/useFetch";
import { getMonetisationPujaApi } from "@/utils/helper";
import { Image, Modal, Table, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";

const InventoriesView = ({ bookingId, inventoryId, handleCloseInventoryModal }: { bookingId: string, inventoryId: string, handleCloseInventoryModal: () => void }) => {
    const [visible, setVisible] = useState(false);

    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)

    useEffect(() => {
        if (!bookingId) return

        setVisible(!!bookingId)
    }, [bookingId])

    const getOrderInfoByBookingId = useCallback(async () => {
        if (!bookingId) {
            return { data: undefined }
        }
        try {
            const bookingRes = await monetisationPujaApi.orderApi.getOrderByBookingId(String(bookingId))
            return {
                data: bookingRes?.data?.data?.order
            }
        } catch (error) {
            console.error("Error fetching address:", error)
            return {
                err: "Failed to fetch address details",
            }
        }
    }, [bookingId, monetisationPujaApi.orderApi])

    const { data: order, error, loading } = useFetch(getOrderInfoByBookingId)

    const skuTableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // align: 'center',  
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            // align: 'center',  
            render: (name: string) => <Tag color='green'>{name}</Tag>
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            // align: 'center',  
        },
        {
            title: 'Image',
            dataIndex: 'image_url',
            key: 'image_url',
            // align: 'center',  
            render: (name: string) => <Image src={name} width={80} height={80} />
        },
    ]

    const filteredAddOns = order?.add_ons?.filter((item: any) => item.id === inventoryId);

    return (
        <Modal
            title={'Addons'}
            visible={visible}
            onCancel={handleCloseInventoryModal}
            footer={null}
        >
            <Table
                columns={skuTableColumns}
                dataSource={filteredAddOns}
                pagination={false}
                loading={loading}

            />
        </Modal>
    )
}
export default InventoriesView