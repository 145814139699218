import { CreateStore } from '@a4b/api/src/modules/Monetisation/Puja/types';
import { Select, Checkbox, Row, Col, Tag, Skeleton } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { ShipmentFiltersType } from '../..';

interface ShipmentFiltersProps {
    stores: CreateStore[] | undefined;
    selectedStoreIds: string[];
    onFilterChange: (filters: Partial<ShipmentFiltersType>) => void;
    isLoadingStores: boolean
}

const handleFilterOption = (input: string, option: any) => {
    if (!input || !option) return true;

    const searchText = input.toLowerCase();
    const storeTitle = option.children ? option.children.toString().toLowerCase() : '';

    const storeId = option.value ? option.value.toString().toLowerCase() : '';

    return storeTitle.startsWith(searchText) || storeId.includes(searchText);
};

const deliveryStatuses = [
    // { value: 'not_deliverable', label: 'Not deliverable' },
    { value: 'deliverable', label: 'Deliverable' },
    { value: "order_created", label: "Order created" },
    { value: "order_failed", label: "Order failed" },
    { value: "pickup_initiated", label: "Pickup initiated" },
    { value: "picked_up", label: "Pickedup" },
    { value: "intransit", label: "In Transit" },
    { value: "delivery_initiated", label: "Delivery initiated" },
    { value: "delivery_success", label: "Delivery success" },
    { value: "delivery_failed", label: "Delivery Failed" },
    { value: "delivery_delayed", label: "Delivery Delayed" },
];

const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                maxWidth: '150px',
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: 3,
            }}
        >
            {/* {label} */}
            <span
                style={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    paddingRight: '8px' // Space for close icon
                }}
            >
                {label}
            </span>
        </Tag>
    );
};

const ShipmentFilters: React.FC<ShipmentFiltersProps> = ({ onFilterChange, selectedStoreIds, stores, isLoadingStores }) => {
    const handleStoreChange = (values: string[]) => {
        onFilterChange({ storeIds: values });
    };

    const handleStatusChange = (value: string | null) => {
        onFilterChange({ deliveryStatus: value });
    };

    const handleBoxChange = (e: CheckboxChangeEvent) => {
        onFilterChange({ aashirwadBox: e.target.checked });
    }

    return (
        <Row style={{ marginBottom: 15, background: 'white', padding: '20px' }}>
            <strong style={{ fontSize: '16px' }}>Filters:</strong>
            <Col span={7} style={{ marginLeft: '20px' }}>
                {
                    isLoadingStores ?
                        <Skeleton paragraph={{ rows: 1 }} active title={{ width: '300px' }} />
                        :
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Stores"
                            value={selectedStoreIds}
                            onChange={handleStoreChange}
                            mode="multiple"
                            allowClear
                            tagRender={tagRender}
                            showSearch
                            optionFilterProp="children"
                            filterOption={handleFilterOption}
                        >
                            {stores?.map(store => (
                                <Select.Option key={store.id} value={store.id}>
                                    {store.title}
                                </Select.Option>
                            ))}
                        </Select>
                }
            </Col>

            {/* Delivery Status Filter */}
            <Col span={7} style={{ marginLeft: '20px' }}>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Filter by Delivery Status"
                    onChange={handleStatusChange}
                    allowClear
                >
                    {deliveryStatuses.map(status => (
                        <Select.Option key={status.value} value={status.value}>
                            {status.label}
                        </Select.Option>
                    ))}
                </Select>
            </Col>

            {/* Aashirwad Box Filter */}
            <Col span={7} style={{ marginLeft: '20px' }}>
                <Checkbox onChange={handleBoxChange}>Show Aashirwad Box</Checkbox>
            </Col>
        </Row>
    );
};

export default React.memo(ShipmentFilters);

