import React, { useCallback, useEffect, useState } from 'react'
import {
    Table,
    Modal,
    Tag,
    Button,
    Image,
    Tooltip,
    Descriptions,
    Form,
    message
} from 'antd'
import { CheckCircleOutlined, MailOutlined, NotificationOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { useAppContext } from 'components/AppContext'
import { Order } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { ColumnType } from 'antd/lib/table'
import { OrderDetailsModal } from '../OrderDetailsModal'

interface FiltersType {
    serviceType: string
    orderStatus: string
    bookingId: string
    contactNumber: string
    userId: string
    storeId: string | undefined
    bookingWhatsAppNumber: string
}
interface Props {
    orderData: Order[] | undefined
    handleTableChange: (type: string) => void
    sendNotification: (record: any) => void
    filters: FiltersType
    triggerEdit: (value: any) => void
    refundHandler: (value: any) => void
    userRole: string
    loading: boolean
    currentPage: number | null
}
interface Address {
    pin_code: string
    city_name: string
    state_name: string
    house_info: string
    area_info: string
    landmark: string
}
interface AddressButtonProps {
    booking_address: Address
}

const PoojaOrdersPage: React.FC<Props> = ({ orderData, handleTableChange, sendNotification, filters, triggerEdit, refundHandler, userRole, loading, currentPage }) => {

    const { networkInstance } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

    const [addInfoModalVisible, setAddInfoModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<any>(null);

    const handleAddInfoShowModal = useCallback((orderData: any) => {
        setSelectedOrder(orderData);
        setAddInfoModalVisible(true);
    }, []);

    const handleAddInfoCloseModal = useCallback(() => {
        setAddInfoModalVisible(false);
        setSelectedOrder(null);
    },[]);

    const dataSource = orderData

    const ShowMemberData = ({ booking_member_data }: any) => {
        const [visible, setVisible] = useState(false)

        const showAddressModal = () => {
            setVisible(true)
        }

        const handleCancel = () => {
            setVisible(false)
        }

        const subTableColumns = [
            {
                title: 'Full Name',
                dataIndex: 'full_name',
                key: 'full_name',
                align: 'center',
            },
            {
                title: 'Gotra',
                dataIndex: 'gotra',
                key: 'gotra',
                align: 'center',
            },
            {
                title: 'Mobile Number',
                dataIndex: 'mobile_number',
                key: 'mobile_number',
                align: 'center',
            },
        ]
        return (
            <>
                {(booking_member_data && booking_member_data?.length) ? <Button type="primary" onClick={showAddressModal}>Member Data</Button> : <p>Empty</p>}
                <Modal
                    title="Member data"
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Table
                        columns={subTableColumns as ColumnType<any>[]}
                        dataSource={booking_member_data}
                        pagination={false} />
                </Modal>
            </>
        );
    }

    const AddressButton: React.FC<AddressButtonProps> = ({ booking_address }) => {
        const [visible, setVisible] = useState(false)

        const showAddressModal = () => {
            setVisible(true)
        }

        const handleCancel = () => {
            setVisible(false)
        }

        return (
            <>
                <Button type="primary" onClick={showAddressModal}>Address</Button>
                <Modal
                    title="Address"
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Descriptions
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1 }}
                    >
                        <Descriptions.Item label="Pincode">{booking_address?.pin_code}</Descriptions.Item>
                        <Descriptions.Item label="City">{booking_address?.city_name}</Descriptions.Item>
                        <Descriptions.Item label="State">{booking_address?.state_name}</Descriptions.Item>
                        <Descriptions.Item label="House">{booking_address?.house_info}</Descriptions.Item>
                        <Descriptions.Item label="Area">{booking_address?.area_info}</Descriptions.Item>
                        <Descriptions.Item label="Landmark">{booking_address?.landmark}</Descriptions.Item>
                    </Descriptions>
                </Modal>
            </>
        )
    }

    const SKUButton = ({ items, title }: any) => {
        const [visible, setVisible] = useState(false);

        const showSKUModal = () => {
            setVisible(true)
        }

        const handleCancel = () => {
            setVisible(false)
        }

        const skuTableColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                align: 'center',
                render: (name: string) => <Tag color='green'>{name}</Tag>
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                align: 'center',
            },
            {
                title: 'Image',
                dataIndex: 'image_url',
                key: 'image_url',
                align: 'center',
                render: (name: string) => <Image src={name} width={80} height={80} />
            },
        ]

        return (
            <>
                <Button type="primary" onClick={showSKUModal}>{title}</Button>
                <Modal
                    title={title}
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Table
                        columns={skuTableColumns as ColumnType<any>[]}
                        dataSource={items}
                        pagination={false} />
                </Modal>
            </>
        )
    }

    const GenerateButton = ({ delivery_status, delivery_data, title, order_id }: any) => {
        const [visibleErr, setVisibleErr] = useState(false);
        const showGenerateModal = () => {
            if (delivery_status === 'deliverable') {
                createClickpostDelivery();
            } else if (delivery_status === 'order_failed') {
                setVisibleErr(true);
            }
        }

        const handleGenerateClick = () => {
            createClickpostDelivery();
            setVisibleErr(false);
        }

        const createClickpostDelivery = async () => {
            try {
                const payload = {
                    order_id: order_id
                };

                const successDelivery = await monetisationOfferingsApi.batchApi.createDeliveryOrder(payload)
                message.success(successDelivery?.data?.data, 2)
            } catch (error) {
                message.error('Error while creating Delivery Order Creation!', 2)
            }
        }

        const handleCancel = () => {
            setVisibleErr(false)
        }

        return (
            <>
                <Button type="primary" onClick={showGenerateModal}>Generate</Button>
                <Modal
                    title={title}
                    visible={visibleErr}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="generate" type="primary"
                            onClick={() => {
                                handleGenerateClick()
                                handleCancel()
                            }}>
                            Generate
                        </Button>

                    ]}
                    closable={false}
                >
                    <Form.Item>
                        {delivery_status === 'order_failed' && (
                            <>
                                <p><strong>Success:</strong> {delivery_data?.meta?.success ? 'true' : 'false'}</p>
                                <p><strong>Message:</strong> {delivery_data?.meta?.message}</p>
                                <p><strong>Reference Number:</strong> {delivery_data?.meta?.reference_number}</p>
                            </>
                        )}
                    </Form.Item>
                </Modal>
            </>
        )
    }

    const Notification = ({ record }: any) => (<Tag onClick={() => sendNotification(record)} icon={<NotificationOutlined />} color='cyan' style={{ cursor: 'pointer' }}>Notify</Tag>)

    const deliveryStatus: { [key: string]: string } = {
        not_deliverable: "Non Deliverable",
        deliverable: "Deliverable",
        order_created: "Order Created",
        order_failed: "Order Failed",
        pickup_initiated: "Pickup Initiated",
        picked_up: "Picked Up",
        intransit: "In Transit",
        delivery_initiated: "Delivery Initiated",
        delivery_success: "Delivery Success",
        delivery_failed: "Delivery Failed"
    };

    const formatDate = (text: string | null | undefined) => {
        if (text) {
            const date = new Date(text);
            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
            const year = date.getUTCFullYear().toString();
            return `${day}-${month}-${year}`;
        }
        return "-";
    };

    const commonColumns = [
        {
            title: 'Order Id',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'Booking Id',
            dataIndex: 'booking_id',
            key: 'booking_id',
            fixed: 'left',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            fixed: 'left',
        },
        {
            title: 'Store',
            dataIndex: 'store_name',
            key: 'store_name',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'User Id',
            dataIndex: 'user_id',
            key: 'user_id',
            ellipsis: {
                showTitle: false,
            },
            render: (user_id: string) => (
                <Tooltip placement="topLeft" title={user_id}>
                    {user_id}
                </Tooltip>),
        },
        {
            title: 'Store Id',
            dataIndex: 'store_id',
            key: 'store_id',
            ellipsis: {
                showTitle: false,
            },
            render: (store_id: string) => (
                <Tooltip placement="topLeft" title={store_id}>
                    {store_id}
                </Tooltip>),
        },
        {
            title: 'Order Status',
            dataIndex: 'order_status',
            key: 'order_status',
            render: (text: string) => {
                if (text) {
                    return (
                        <Tag color='green' key={text}>
                            {text.toUpperCase()}
                        </Tag>
                    )
                }
                return "-"
            }
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 150,
            render: formatDate,
        },
        {
            title: 'Schedule Date',
            dataIndex: 'schedule_date',
            key: 'schedule_date',
            width: 150,
            render: formatDate,
        },
        {
            title: 'Completed Date',
            dataIndex: 'completed_date',
            key: 'completed_date',
            width: 150,
            render: formatDate,
        },
        {
            title: 'User name',
            dataIndex: 'user_name',
            key: 'user_name',
            width: 150,
            ellipsis: true,
            render: (text: string) => {
                if (text) {
                    return text
                }
                return "-"
            }
        },
        {
            title: 'Contact',
            dataIndex: 'contact_number',
            key: 'contact_number',
            render: (text: string) => {
                if (text) {
                    return (
                        <a href={`tel:${text}`}>
                            {text}
                        </a>
                    )
                }
                return "-"
            }
        },
        {
            title: 'SKU',
            dataIndex: 'items',
            key: 'items',
            width: 150,
            render: (text: string, record: any) => (
                <SKUButton items={record?.items} title='SKU Items' />
            ),
        },
        {
            title: 'Delivery Status',
            dataIndex: 'delivery_status',
            key: 'delivery_status',
            width: 150,
            ellipsis: true,
            render: (text: string) => {
                if (text) {
                    return deliveryStatus[text]
                }
                return "-"
            }
        },
        {
            title: 'Clickpost Order',
            dataIndex: 'clickpost_order',
            key: 'clickpost_order',
            width: 150,
            render: (text: string, record: any) => {
                return (
                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {(record?.delivery_status === 'deliverable' || record?.delivery_status === 'order_failed') ?
                            <GenerateButton delivery_status={record?.delivery_status} delivery_data={record?.delivery_data} title={'Clickpost order Creation'} order_id={record?.id} /> :
                            '-'
                        }
                    </span>
                );
            },
        },
        {
            title: 'Additional Info',
            dataIndex: 'additional_info',
            key: 'additional_info',
            width: 150,
            render: (text: string, record: any) => (
                <Button
                    type='link'
                    color='cyan'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleAddInfoShowModal(record)}
                >
                    view
                </Button>
            ),
        }
    ]

    const editButton = {
        title: 'Edit',
        dataIndex: 'edit',
        key: 'edit',
        fixed: 'right',
        render: (text: string, record: any) =>
            <Button
                type='link'
                color='cyan'
                style={{ cursor: 'pointer' }}
                onClick={() => triggerEdit(record)}
            >
                Edit
            </Button>

    }

    const refundButton = {
        title: 'Refund',
        dataIndex: 'refund',
        key: 'refund',
        render: (text: string, record: any) => {
            if (record?.order_status === 'refunded') {
                return <Tag
                    color='#00BD68'
                    style={{ cursor: 'pointer' }}
                >
                    Refunded<CheckCircleOutlined style={{ marginLeft: '5px' }} />
                </Tag>
            } else {
                return <Button
                    type='primary'
                    style={{ cursor: 'pointer' }}
                    onClick={() => refundHandler(record)}
                >
                    Refund
                </Button>
            }
        }
    }

    const offeringName = {
        title: 'Offerings username',
        dataIndex: 'offering_user_name',
        key: 'offering_user_name',
        render: (text: string, record: any) => {
            if (text) {
                return text
            } else {
                return '-'
            }
        }
    }

    const addOns =
    {
        title: 'Add Ons',
        dataIndex: 'add_ons',
        key: 'add_ons',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (text: string, record: any) => (
            <SKUButton items={record?.add_ons} title='Add Ons' />
        ),
    }

    let columns = []
    if (filters?.serviceType === 'pooja') {
        columns =
            [
                ...commonColumns,
                addOns,
                {
                    title: (
                        <>
                            <WhatsAppOutlined width={30} height={30} color='green' /> WA Number
                        </>
                    ),
                    dataIndex: 'booking_whatsapp_number',
                    key: 'booking_whatsapp_number',
                    width: 150,
                    render: (text: string) => {
                        if (text) {
                            return (
                                <a href={`tel:${text}`}>
                                    {text}
                                </a>
                            )
                        }
                        return "-"
                    }
                },
                {
                    title: (
                        <>
                            <MailOutlined width={30} height={30} color='green' /> User Email
                        </>
                    ),
                    dataIndex: 'user_email',
                    key: 'user_email',
                    width: 150,
                    render: (text: string) => {
                        if (text) {
                            return (
                                <span style={{ color: '#1890ff' }}>
                                    {text}
                                </span>
                            )
                        }
                        return "-"
                    }
                },
                {
                    title: 'Member data',
                    key: 'booking_member_data',
                    dataIndex: 'booking_member_data',
                    width: 150,
                    render: (text: string, record: any) => {
                        return <ShowMemberData booking_member_data={record?.booking_member_data} />
                    }
                },
                {
                    title: 'Opted Prasad',
                    dataIndex: 'is_opted_for_prasad',
                    key: 'is_opted_for_prasad',
                    render: (text: boolean) => {
                        if (String(text) === 'true') {
                            return <Tag color='green' >
                                {String(text)}
                            </Tag>
                        } else if (String(text) === 'false') {
                            return <Tag color='red'>
                                {String(text)}
                            </Tag>
                        }
                        return "-"
                    },
                },
                {
                    title: 'Address',
                    key: 'booking_address',
                    dataIndex: 'booking_address',
                    render: (text: string, record: any) => {
                        if (record?.is_opted_for_prasad) {
                            return <AddressButton booking_address={record?.booking_address} />
                        } else {
                            return <p>Empty</p>
                        }
                    },
                },
                ...(((userRole === 'super_admin' && filters?.orderStatus === 'booked') && refundButton) ? [refundButton] : []),
                {
                    title: 'Notify',
                    key: 'booking_member_data',
                    dataIndex: 'booking_member_data',
                    fixed: 'right',
                    render: (text: string, record: any) => {
                        if (!record?.booking_member_data || record?.booking_member_data === '' || record?.booking_member_data?.length === 0) {
                            return <Notification record={record} />
                        }
                        return "-"
                    },
                },
                editButton
            ]
    }
    else {
        const columnsCopy: any = [...commonColumns]
        columnsCopy.splice(3, 0, offeringName)
        if (userRole === 'super_admin' && filters?.orderStatus === 'booked') {
            columnsCopy.push(refundButton)
        }
        columnsCopy.push(editButton)
        columns = columnsCopy
    }

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                scroll={{ x: 2500 }}
                size='large'
                loading={loading}
                pagination={false}
            />
            {
                currentPage &&
                <div style={{ display: 'flex', justifyContent: 'right', width: '100%', paddingTop: '10px' }}>
                    <Button onClick={() => handleTableChange('previous')}>Prev</Button>
                    <Button style={{ marginLeft: '5px', marginRight: '5px' }}>{String(currentPage)}</Button>
                    <Button onClick={() => handleTableChange('next')}>Next</Button>
                </div>
            }
            {addInfoModalVisible && (
                <OrderDetailsModal orderData={selectedOrder} onClose={handleAddInfoCloseModal} showModal={addInfoModalVisible} />
            )}
        </>
    )
}

export default PoojaOrdersPage

