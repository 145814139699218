import { useAppContext } from "@/components/AppContext";
import { getMonetisationPujaApi } from "@/utils/helper";
import { Modal, Spin, Tag } from "antd";
import { useCallback } from "react";
import { StoreData } from "../../types";
import useFetch from "@/hooks/useFetch";
import EmptyState from '@/components/ui/EmptyState';
import { CustomLoader } from "@/components/Loader";

interface ClickPostConfirmationPopupProps {
    showClickPostConfirmationPopup: boolean
    onModalOkCallback: () => void
    onModalCancelCallback: () => void
    store: { id: string, title: string }
}
const ClickPostConfirmationPopup: React.FC<ClickPostConfirmationPopupProps> = ({ showClickPostConfirmationPopup, onModalOkCallback, onModalCancelCallback, store }) => {
    const { networkInstance, countryState, app } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)


    const fetchPujaUpdates = useCallback(async (storeId: string | undefined) => {
        if (!storeId) {
            return { err: 'Store ID is required' }
        }

        try {
            const storeResponse = await monetisationPujaApi.storesApi.getPujaUpdatesByStoreId(storeId, countryState?.countryCode)
            const storeResponseData: StoreData = storeResponse.data.data
            return {
                data: storeResponseData
            }
        } catch (err) {
            return {
                err: "Failed to fetch puja updates details"
            }
        }
    }, [countryState?.countryCode, monetisationPujaApi.storesApi])

    const { data, error, loading } = useFetch(fetchPujaUpdates, store?.id)

    return (
        <Modal
            title='Do you want to create click post?'
            visible={showClickPostConfirmationPopup}
            onOk={onModalOkCallback}
            onCancel={onModalCancelCallback}
            okText='Create'
            cancelText='Cancel'
            okButtonProps={{
                disabled: data?.puja_store_state !== "COMPLETED"
            }}
        >
            {
                error ?
                    <EmptyState errorText="Error while fetching Store Info!" />
                    :
                    loading ?
                        <Spin indicator={<CustomLoader />} style={{ width: '100%' }} />
                        :
                        <div style={{
                            display: "flex", alignItems: "center", maxWidth: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                        }}>
                            <span>Store:</span>
                            <Tag
                                style={{
                                    marginLeft: "15px",
                                    maxWidth: "100%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {store.title}
                            </Tag>
                            <Tag
                                style={{
                                    marginLeft: "15px",
                                    maxWidth: "100%",
                                    minWidth: '18%',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: 'center'
                                }}
                                color="blue"
                            >
                                {data?.puja_store_state}
                            </Tag>
                        </div>
            }

        </Modal>

    )
}

export default ClickPostConfirmationPopup;