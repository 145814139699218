import {
  Address,
  AddressPayload,
} from '@a4b/api/src/modules/Monetisation/Puja/types/address'

export const prepareAddressPayload = (data: AddressPayload): AddressPayload => {
  return {
    ...data,
  }
}

export const parseAddress = (data: Address) => {
  return {
    ...data,
  }
}
