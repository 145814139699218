import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { useCallback, useEffect, useState } from 'react'
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { useAppContext } from '@/components/AppContext'
import Error from '@/components/Error'
import { TablePaginationConfig } from 'antd/es/table'
import { useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import PujaList from './components/PujaList'
import useTableConfig from './hooks/useTableConfig'
import PujaUpdateForm from './components/PujaUpdateForm'
import { getMonetisationPujaApi } from '@/utils/helper'
import ClickPostConfirmationPopup from './components/ClickpostConfirmModal'

const getPageBreadcrumbs = () => [
    {
        path: '/monetisation/puja/temples',
        breadcrumbName: 'Manage Stores',
    },
    {
        path: '/monetisation/puja',
        breadcrumbName: 'Hub List',
    },
    {
        path: '/monetisation/puja/puja-updates',
        breadcrumbName: 'Puja Updates',
    },
]

const ManagePujaUpdates = () => {
    const { networkInstance, countryState, app } = useAppContext()
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance)
    const [searchParams, setSearchParams] = useSearchParams()

    const [storeList, setStoreList] = useState<CreateStore[]>([])
    const [errorPage, setErrorPage] = useState<boolean>(false)
    const [pagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 1000,
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showPujaUpdateDrawer, setShowPujaUpdateDrawer] = useState<boolean>(false)
    const [showClickPostConfirmationPopup, setShowClickPostConfirmationPopup] = useState<boolean>(false)
    const [store, setStore] = useState<{ id: string, title: string }>({
        id: '',
        title: ''
    })
    const tableConfig = useTableConfig(storeList, isLoading, setShowPujaUpdateDrawer, setShowClickPostConfirmationPopup, setStore)

    const getStoreList = useCallback((async () => {
        try {
            setIsLoading(true)
            const storeList = await monetisationPujaApi.storesApi.getStoreList({
                limit: pagination.pageSize || 1000,
                offset: 0,
            }, countryState?.countryCode, undefined, undefined,
                searchParams.get('sort_by') as ('created_at' | 'position') || 'created_at',
                searchParams.get('is_archive'),
                searchParams.get('is_active') || 'true',
                searchParams.get('service_type' as ('pooja')) || 'pooja'
            )

            setStoreList(storeList?.data?.data?.store_list)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            setErrorPage(true)
            message.error({
                content: 'Error while fetching store list',
                duration: 3,
            })
            console.log(error)
        }
    }), [monetisationPujaApi.storesApi, pagination.pageSize, countryState?.countryCode, searchParams])

    useEffect(() => {
        getStoreList()
    }, [getStoreList])

    const updateFilters = useCallback((updateType: 'sortBy' | 'isArchive' | 'isActive' | 'serviceType', value?: 'true' | 'false' | 'created_at' | 'position' | 'pooja') => {
        const paramMapping: Record<string, string> = {
            sortBy: 'sort_by',
            isArchive: 'is_archive',
            isActive: 'is_active',
            serviceType: 'service_type',
        };

        const paramName = paramMapping[updateType]

        if (paramName && value !== undefined) {
            searchParams?.set(paramName, value)
        } else if (paramName) {
            searchParams?.delete(paramName)
        }
        setSearchParams(searchParams)
    }, [searchParams, setSearchParams])

    const handleCloseForm = useCallback((updateStoreList = false) => {
        setShowPujaUpdateDrawer(false)
        setStore({ id: '', title: '' })
        updateStoreList &&
            setTimeout(() => {
                getStoreList()
            }, 500)
    }, [getStoreList])

    const onModalCancel = useCallback(() => {
        setShowClickPostConfirmationPopup(false)
    }, [])

    const onModalOk = useCallback(async () => {
        message.loading('loading...', 2)
        const storeId = store.id
        if (!storeId) {
            message.error('Store Id not found!')
            return
        }
        try {
            await monetisationPujaApi.storesApi.createClickPostTicket(storeId, countryState?.countryCode)
            onModalCancel()
            message.info('Click post creation initiated!', 2)
            setTimeout(() => {
                getStoreList()
            }, 500)
        } catch (error) {
            message.error({
                content: 'Error while creating click post',
                duration: 2,
            })
            console.log(error)
        }
    }, [countryState?.countryCode, getStoreList, monetisationPujaApi.storesApi, onModalCancel, store.id])


    return (
        <div style={{ padding: '0 40px' }}>
            {errorPage ?
                <Error text='Error While fetching Hub List!' />
                :
                <>
                    <AdminBreadcrumb routes={getPageBreadcrumbs()} />
                    <PujaList
                        tableConfig={tableConfig}
                        sortBy={searchParams.get('sort_by') as ('created_at' | 'position') || 'created_at'}
                        isActive={searchParams.get('is_active') as ('true' | 'false') || 'true'}
                        updateFilters={updateFilters}
                    />
                    {
                        store?.id &&
                        <PujaUpdateForm
                            showForm={showPujaUpdateDrawer}
                            store={store!}
                            handleCloseForm={handleCloseForm}
                        />
                    }
                    {
                        showClickPostConfirmationPopup &&
                        <ClickPostConfirmationPopup
                            showClickPostConfirmationPopup={showClickPostConfirmationPopup}
                            onModalOkCallback={onModalOk}
                            onModalCancelCallback={onModalCancel}
                            store={store}
                        />
                    }
                </>
            }
        </div>
    )
}
export default withDefaultLayout(ManagePujaUpdates)
