import { CustomLoader } from "@/components/Loader";
import { Button, Row, Spin, TableProps, Tag, Tooltip } from "antd";
import { useMemo } from "react";
import type { ColumnsType } from 'antd/es/table';
import { Shipment } from "@a4b/api/src/modules/Monetisation/Puja/types/shipments";

const getDeliveryStatusColor = (status: string) => {
    const statusColorMap: Record<string, string> = {
        'not_deliverable': 'default',
        'deliverable': 'default',
        'order_created': 'blue',
        'order_failed': 'red',
        'pickup_initiated': 'default',
        'picked_up': 'default',
        'intransit': 'purple',
        'delivery_initiated': 'default',
        'delivery_success': 'green',
        'delivery_failed': 'red',
        'delivery_delayed': 'default'
    };

    return statusColorMap[status] || 'default';
};

const useTableConfig = (
    shipments: Shipment[],
    isLoading: boolean,
    showGenerateModal: (record: any) => void,
    handleInventoryBtnClick: (record: any) => void
): TableProps<Shipment> => {
    const dataColumns: ColumnsType<Shipment> = useMemo(() => [
        {
            title: 'ID',
            key: 'id',
            width: '15%',
            dataIndex: 'id',
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'Type',
            width: '15%',
            key: 'type',
            dataIndex: 'type'
        },
        {
            title: 'Store ID',
            width: '15%',
            key: 'store_id',
            dataIndex: 'store_id',
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'Inventory ID',
            width: '15%',
            key: 'inventory_id',
            dataIndex: 'inventory_id',
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => <Tooltip placement="topLeft" title={id}>
                {id}
            </Tooltip>,
        },
        {
            title: 'Addons',
            dataIndex: 'add_ons',
            width: '15%',
            key: 'add_ons',
            render: (_, record: any) => (
                !record?.inventory_id ? 'N/A' :
                    <Button type="primary" onClick={() => handleInventoryBtnClick(record)}> {'Addons'}</Button>
            )
        },
        {
            title: 'Booking ID',
            width: '15%',
            key: 'booking_id',
            dataIndex: 'booking_id'
        },
        {
            title: 'Tracking ID',
            width: '12%',
            key: 'tracking_id',
            render: (_, record) => <Row justify="space-between" style={{ alignItems: 'center' }}>
                <span>
                    {record?.delivery_data?.tracking_id || 'N/A'}
                </span>
            </Row>,
        },
        {
            title: 'Delivery Status',
            width: '15%',
            key: 'delivery_status',
            dataIndex: 'delivery_status',
            fixed: 'right',
            render: (status) => (
                <Tag
                    color={getDeliveryStatusColor(status)}
                    style={{
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        borderRadius: '4px'
                    }}
                >
                    {status.replace(/_/g, ' ')}
                </Tag>
            )
        },
        {
            title: 'Edit',
            width: '10%',
            key: 'edit',
            render: (_, record) => {
                // if (record.delivery_status === 'order_failed') {
                //     return <span>N/A</span>
                // }
                return (
                    <Button disabled={record.delivery_status !== 'order_failed'} type="link" onClick={() => showGenerateModal(record)}>Edit</Button>
                )
            },
            fixed: 'right'
        },
    ], [handleInventoryBtnClick, showGenerateModal])

    return {
        pagination: false, dataSource: shipments, columns: dataColumns, loading: {
            spinning: isLoading,
            indicator: <Spin indicator={<CustomLoader />} />,
        }, size: 'middle', scroll: { x: 1500 }
    }
}

export default useTableConfig