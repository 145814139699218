import { Fragment, useCallback, useMemo, useState } from 'react'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { Card, Table } from 'antd';
import useTableConfig from './hooks/useTableConfig'
import { Shipment } from '@a4b/api/src/modules/Monetisation/Puja/types/shipments'
import useFetch from '@/hooks/useFetch'
import EmptyState from '@/components/ui/EmptyState';
import { getMonetisationPujaApi } from '@/utils/helper';
import styled from 'styled-components';
import ShipmentFilters from './components/ShipmentFilters';
import { useSearchParams } from 'react-router-dom';
import ClickPostDetails from './components/ClickPostDetails';
import InventoriesView from './components/InventoriesView';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`;
export interface ShipmentFiltersType {
    deliveryStatus: string | null;
    aashirwadBox: boolean;
    storeIds: string[];
}
export type ShipmentDetails = Pick<Shipment, "id" | "booking_id" | "delivery_data" | "shipment_user_data" | "inventory_id"> | null

const ManageShipments = () => {
    const { networkInstance, app } = useAppContext();
    const monetisationPujaApi = getMonetisationPujaApi(app, networkInstance);
    const [searchParams] = useSearchParams();

    const [filters, setFilters] = useState<ShipmentFiltersType>({
        deliveryStatus: null,
        aashirwadBox: false,
        storeIds: []
    });
    const [shipment, setShipment] = useState<ShipmentDetails>(null)
    const [showClickPostForm, setShowClickPostForm] = useState(false)
    const [showInventoryInfo, setShowInventoryInfo] = useState(false)

    const countryCode = searchParams.get("country_code") || "IN";
    const languageCode = searchParams.get("language_code") || "hi";

    const getStores = useCallback(async () => {
        try {
            const storeListParams = { limit: 2000, offset: 0 };
            const storeListRes = await monetisationPujaApi.storesApi.getStoreList(
                storeListParams,
                countryCode,
                undefined,
                languageCode,
                'created_at',
                undefined,
                null,
                'pooja'
            );
            return { data: storeListRes?.data?.data?.store_list || [] };
        } catch (error) {
            console.error("Error fetching stores: ", error);
            return { err: "Failed to load stores" };
        }
    }, [monetisationPujaApi.storesApi, countryCode, languageCode]);

    const { data: stores, error: storesError, loading: isLoadingStores } = useFetch(getStores);

    const getShipments = useCallback(async () => {
        if (filters.storeIds.length === 0) return { data: [] };
        try {
            const storeIdsString = filters.storeIds.join(",");
            const shipmentListRes = await monetisationPujaApi.shipmentApi.getShipments(storeIdsString);
            return { data: shipmentListRes?.data?.data?.shipments || [] };
        } catch (error) {
            console.error("Error fetching shipments: ", error);
            return { err: "Failed to fetch shipments" };
        }
    }, [monetisationPujaApi?.shipmentApi, filters.storeIds]);

    const { data: shipmentsData, error: shipmentsError, loading: isLoadingShipments, refetch: refetchShipments } = useFetch(getShipments);

    const filteredData = useMemo(() => {
        return (shipmentsData || []).filter((shipment: any) => {
            if (filters.deliveryStatus && shipment.delivery_status !== filters.deliveryStatus) {
                return false;
            }

            if (filters.aashirwadBox && shipment.type !== "AASHIRWAD_BOX") {
                return false;
            }

            return true;
        });
    }, [shipmentsData, filters]);

    const handleFilterChange = useCallback((newFilters: Partial<ShipmentFiltersType>) => {
        setFilters(prev => ({ ...prev, ...newFilters }));
    }, []);

    const handleGenerateBtnClick = useCallback((record: any) => {
        setShowClickPostForm(true)
        setShipment(record)
    }, [])

    const handleInventoryBtnClick = useCallback((record: any) => {
        setShowInventoryInfo(true)
        setShipment(record)
    }, [])

    const tableConfig = useTableConfig(filteredData as Shipment[], isLoadingShipments, handleGenerateBtnClick, handleInventoryBtnClick);

    const handleCloseDeliveryDataDrawer = useCallback((refetch: boolean = false) => {
        setShipment(null)
        setShowClickPostForm(false)

        if (refetch) {
            refetchShipments()
        }
    }, [refetchShipments])

    const handleCloseInventoryModal = useCallback(() => {
        setShowInventoryInfo(false)
        setShipment(null)
    }, [])

    if (storesError) {
        return (
            <EmptyState errorText={storesError} />
        )
    }

    if (stores && stores.length === 0) {
        return (
            <EmptyState errorText="No stores found!" />
        )
    }

    return (
        <div style={{ padding: '60px 40px' }}>
            <Fragment>
                <ShipmentFilters
                    stores={stores}
                    selectedStoreIds={filters.storeIds}
                    onFilterChange={handleFilterChange}
                    isLoadingStores={isLoadingStores}
                />
                <Container title='Manage Shipments' style={{ width: '100%' }}>
                    {shipmentsError ? (
                        <EmptyState errorText='Failed to load shipments!' />
                    ) : (
                        <Table {...tableConfig} />
                    )}
                </Container>
                {
                    showClickPostForm &&
                    <ClickPostDetails showDeliveryDataDrawer={showClickPostForm} shipment={shipment} closeFormCallback={handleCloseDeliveryDataDrawer} />
                }
                {
                    showInventoryInfo &&
                    <InventoriesView bookingId={String(shipment?.booking_id)} inventoryId={shipment?.inventory_id as string} handleCloseInventoryModal={handleCloseInventoryModal} />
                }
            </Fragment>
        </div>
    );
};

export default withDefaultLayout(ManageShipments);
