import { Form, Input, Row, Col, Button } from "antd";
import React from "react";
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

interface Props {
    fields: any;
    cdnUrl: string;
    preSignUrl: string;
    add: () => void;
    remove: (item: any) => void;
    datatype: string;
    form: any;
}

const FormItems = ({ field, remove, cdnUrl, preSignUrl }: any) => {
    return (
        <div style={{
            border: "1px solid #e0dcdc",
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "white",
        }}>
            <Row gutter={16} align="middle">
                {/* Image Upload */}
                <Col span={8}>
                    <Form.Item
                        label="Image URL"
                        name={[field.name, "img_url"]}
                        valuePropName="fileList"
                        rules={[
                            {
                                required: true,
                                min: 0,
                                max: 1,
                                validator: async (rules: any, values: any) => {
                                    if (values?.length < rules.min) {
                                        return Promise.reject(' Image Url')
                                    }
                                },
                            },
                        ]}
                    >
                        <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={0} max={1} />
                    </Form.Item>
                </Col>

                {/* Deeplink Input */}
                <Col span={20}>
                    <Form.Item
                        label="Deeplink"
                        name={[field.name, "deeplink"]}
                        rules={[{ required: true, message: "Enter Deeplink" }]}
                    >
                        <Input placeholder="Enter Deeplink" />
                    </Form.Item>
                </Col>

                {/* Remove Button */}
                <Col span={2}>
                    <Button onClick={() => remove(field.name)} type="ghost" icon={<DeleteOutlined />} danger />
                </Col>
            </Row>
        </div>
    );
};

const ContentSection: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype }) => {
    return (
        <>
            {fields.map((field: any) => (
                <FormItems key={field.key} field={field} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
            ))}
            <Row>
                <Col>
                    <Button onClick={() => add()} type="ghost" icon={<PlusCircleOutlined />}>Cards</Button>
                </Col>
            </Row>
        </>
    );
};

export default ContentSection;
