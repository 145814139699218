import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import { Product } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { A4B_APPS } from '@/utils/constants'
import { formatItemCreation } from './helper'
import ItemCreation from '../ItemCreation'

interface Props {
  showItemCreation: boolean
  isEditJourney: boolean
  closeItemCreation: (fetchProductList: boolean) => void
  formData?: Product
}

const CreateItem: React.FC<Props> = ({
  showItemCreation,
  closeItemCreation,
  isEditJourney,
  formData,
}) => {
  const { networkInstance, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

  const [editFormData, setEditFormData] = useState<any>()
  const [createItemForm] = useForm()

  useEffect(() => {
    if (isEditJourney) {
      const editForm = {
        ...formData,
        name: formData?.title,
        image_url: [formData?.image_url],
        bottomsheet_image_url: [formData?.bottomsheet_image_url],
        intro_video_url: [formData?.intro_video_url],
        outro_video_url: [formData?.outro_video_url],
        bottomsheet_medias: formData?.bottomsheet_medias?.map((item) => item?.media_url),
        is_igst: formData?.is_igst ?? false,
      }
      setEditFormData(editForm)
      createItemForm.setFieldsValue(editForm)
    } else {
      setTimeout(() => {
        createItemForm.resetFields()
      }, 300)
      setEditFormData({})

    }
  }, [isEditJourney, formData, createItemForm])

  const handleCreateOrUpdate = async (values: any) => {
    const parsedData = formatItemCreation(values)
    if (isEditJourney) {
      try {
        const id = formData?.id || ''
        await monetisationPujaApi.productApi.updateItem(
          { ...parsedData, ...{ id: id } },
          id,
        )
        message.success('Item updated successfully')
        createItemForm.resetFields()
        closeItemCreation(true)
      } catch (error: any) {
        message.error({
          content: error?.response?.data?.error?.args?.type?.[0] || 'Error While updating item',
          duration: 2,
          key: 'update-item',
        })
      }
    } else {
      try {
        message.loading({ content: 'Creating item...', key: 'create-item' })
        await monetisationPujaApi.productApi.createItem(
          parsedData,
        )
        message.success({
          content: 'Item Created Successfully',
          duration: 2,
          key: 'create-item',
        })
        createItemForm.resetFields()
        closeItemCreation(true)
      } catch (error: any) {
        message.error({
          content: error?.response?.data?.error?.args?.type?.[0] || 'Error While creating item',
          duration: 2,
          key: 'create-item',
        })
      }
    }
  }

  const formSubmithandler = () => {
    createItemForm
      .validateFields()
      .then(values => {
        handleCreateOrUpdate(values)
      })
      .catch(errorInfo => {
        message.error("Form validation failed")
      })
  }

  return (
    <ItemCreation
      visible={showItemCreation}
      onClose={closeItemCreation}
      formSubmithandler={formSubmithandler}
      form={createItemForm}
      initialValues={editFormData}
      isEditJourney={isEditJourney}
    />
  )
}

export default CreateItem
