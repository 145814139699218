/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import S3Upload from '../S3Upload'
import { Button, Col, Drawer, Form, Input, Row, Select, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

const { Option } = Select

interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: any
  isEditJourney: boolean
}

const ItemCreation: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney
}) => {
  return (
    <Drawer
      title='Create New Product'
      placement='right'
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}

    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
      >
        <Form.Item
          label='Name of the Product'
          name='title'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: true, message: 'Please input item name!' }]}
        >
          <Input placeholder='Enter Name of the item' />
        </Form.Item>

        <Form.Item
          label='Description'
          name='description'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 20 }}
          rules={[{ required: true, message: 'Please input description!' }]}
        >
          <TextArea placeholder='Enter description of the item' />
        </Form.Item>

        <Form.Item
          label='Product Type'
          name='item_type'
          rules={[{ required: true, message: 'Please select item type!' }]}
        >
          <Select
            style={{ width: 150 }}
            placeholder='Select Type'
          >
            <Option value='item'>Item</Option>
            <Option value='prayer'>Prayer</Option>
            <Option value='pooja'>Pooja</Option>
            <Option value='sankalp'>Sankalp</Option>
            <Option value='combo_item'>Combo Item</Option>
            <Option value='combo_sankalp'>Combo Sankalp</Option>
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const itemType = getFieldValue('item_type')

            return (
              <>
                {itemType !== 'combo_item' && itemType !== 'combo_sankalp' && (
                  <Form.Item
                    label='Product Price'
                    name='price'
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    rules={[{ required: true, message: 'Please input price of item!' }]}
                  >
                    <Input type='number' placeholder='Enter Price' />
                  </Form.Item>
                )}

                {(itemType === 'item' || itemType === 'sankalp') && (
                  <>
                    <Form.Item
                      label='HSN Code'
                      name='hsn_code'
                      rules={[{ required: false, message: 'Please input HSN code' }]}
                    >
                      <Input placeholder='Enter HSN Code' />
                    </Form.Item>

                    <Form.Item
                      label="Tax Rate (%)"
                      name="tax_rate"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value || (typeof value === "number" && value >= 0)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("Tax rate must be a positive number!"))
                          },
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Tax Rate"
                        onChange={(e) => {
                          const value = e.target.value;
                          form.setFieldsValue({ tax_rate: value ? Number(value) : undefined })
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Is IGST Applicable'
                      name='is_igst'
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  </>
                )}
              </>
            )
          }}
        </Form.Item>

        <Row>
          <Col span={12}>

            <Form.Item
              label='Image URL'
              name='image_url'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              valuePropName='fileList'
              rules={[
                {
                  min: 1,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Please upload Image')
                    }
                  },
                },
              ]}
            >
              <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={1} max={1} />
            </Form.Item>

            <Form.Item
              label='Bottom Sheet Image'
              name='bottomsheet_image_url'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              valuePropName='fileList'
              rules={[
                {
                  min: 1,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Please upload bottom sheet Image')
                    }
                  },
                },
              ]}
            >
              <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={1} max={1} />
            </Form.Item>
          </Col>

        </Row>

        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: '30px' }}
        >
          {isEditJourney ? <Button type='primary' onClick={formSubmithandler}>
            Update Product
          </Button> : <Button type='primary' onClick={formSubmithandler}>
            Create Product
          </Button>}
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default ItemCreation
